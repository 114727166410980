@import url(https://fonts.googleapis.com/css2?family=Open+Sans:wght@700&family=Poppins:wght@300;400;500;600&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Open+Sans:wght@600&display=swap);
*,
ul {
  margin: 0px;
  padding: 0px;
  box-sizing: border-box;
}
p {
  margin: 0;
}
a {
  text-decoration: none;
}
input,
select:focus-visible {
  border-bottom: 1px solid #000;
  outline: 0;
}
.btn:hover {
  color: #fff;
  box-shadow: none;
}
.form-select:focus {
  outline: 0;
  box-shadow: none;
}
.btn-outline:hover {
  color: #0d9edf;
}
.h-42 {
  height: 42px;
}
.h-53 {
  height: 53px;
  line-height: 16px;
}
.txt-4 {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 25px;
  /* identical to box height */

  color: #000000;
}
.small .btn {
  width: 83px;
  height: 34px;
  padding: 0;
  line-height: 0;
  font-size: 13px;
  font-family: 'Poppins';
}
input[type='checkbox'] {
  width: auto;
}
span.mob-btn {
  position: absolute;
  top: 25px;
  z-index: 999;
  left: 20px;
  display: none;
}
.btn-outline {
  border: 1px solid #0d9edf;
  box-sizing: border-box;
  border-radius: 32px;
  height: 53px;
  width: 129px;
  line-height: 38px;
  color: #0d9edf;
  font-weight: 500;
  font-family: 'Poppins';
  background-color: transparent;
}
.form-control:-webkit-autofill {
  background-color: #fff;
}
.form-control:autofill {
  background-color: #fff;
}
.form-control:focus {
  border-color: none;
  box-shadow: none;
}
.form-control,
select,
input {
  border-bottom: 1px solid #000;
  border-top: 0px;
  border-left: 0px;
  border-right: 0px;
  border-color: #000;
  width: 100%;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #000842;
  background-color: none;
  border-radius: 0px;
}
.modal {
  background: #ffffff91;
}
::placeholder {
  color: #000;
  opacity: 1;
}
:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #000;
}

::-ms-input-placeholder {
  /* Microsoft Edge */
  color: #000;
}

.navbar {
  position: absolute;
  width: 100%;
  padding: 0px 60px;
}
nav .btn-xl {
  border-radius: 32px;
  padding: 14px 30px;
  border: none;
  color: #fff;
  font-family: 'Poppins';
  background: #0d9edf;
}
nav .btn-xl:hover {
  color: #fff;
}
.navbar-light .navbar-toggler {
  border-color: transparent;
}
.navbar-toggler:focus {
  box-shadow: none;
}

.navbar .navbar-toggler {
  z-index: 9;
}
.navbar-toggler {
  width: 20px;
  height: 20px;
  position: relative;
  transition: 0.5s ease-in-out;
}

.navbar-toggler,
.navbar-toggler:focus,
.navbar-toggler:active,
.navbar-toggler-icon:focus {
  outline: none;
  box-shadow: none;
  border: 0;
}

.navbar-toggler span {
  margin: 0;
  padding: 0;
}

.toggler-icon {
  display: block;
  position: absolute;
  height: 3px;
  width: 100%;
  background: #ffffff;
  border-radius: 1px;
  opacity: 1;
  left: 0;
  transform: rotate(0deg);
  transition: 0.25s ease-in-out;
}

.middle-bar {
  margin-top: 0px;
}

/* when navigation is clicked */

.navbar-toggler .top-bar {
  margin-top: 0px;
  transform: rotate(135deg);
}

.navbar-toggler .middle-bar {
  opacity: 0;
  filter: alpha(opacity=0);
}

.navbar-toggler .bottom-bar {
  margin-top: 0px;
  transform: rotate(-135deg);
}

/* State when the navbar is collapsed */

.navbar-toggler.collapsed .top-bar {
  margin-top: -7px;
  transform: rotate(0deg);
}

.navbar-toggler.collapsed .middle-bar {
  opacity: 1;
  filter: alpha(opacity=100);
}

.navbar-toggler.collapsed .bottom-bar {
  margin-top: 7px;
  transform: rotate(0deg);
}

/* Color of 3 lines */

.navbar-toggler.collapsed .toggler-icon {
  background: linear-gradient(
    263deg,
    rgb(255, 255, 255) 0%,
    rgb(255, 255, 255) 100%
  );
}

/* header  */
.masthead {
  background: url(/static/media/banner.44844412.png) no-repeat center scroll;
  background-size: cover;
}
.masthead h1 {
  font-family: 'Open Sans';
  font-weight: 700;
  font-size: 60px;
  line-height: 82px;
  text-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
}
.masthead p {
  font-family: 'Poppins';
  color: #fff;
  font-size: 18px;
  font-weight: 600;
}
.masthead .btn-xl {
  margin-top: 50px;
}
.btn-outline-white {
  border-radius: 32px;
  padding: 14px 30px;
  color: #fff;
  font-family: 'Poppins';
  border: 2px solid #fff;
  margin-right: 21px;
}
.btn-xl {
  font-family: 'Poppins';
  background: #0d9edf;
  box-shadow: 0px 4px 26px rgba(0, 0, 0, 0.25);
  border-radius: 32px;
  padding: 20px 50px;
  border: none;
  color: #fff;
  font-weight: 500;
  /* height: 53px;
    line-height: 19px; */
}

/* about  */
.about {
  padding: 100px;
}
.about .wrap {
  display: flex;
}
.about span {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 30px;
  text-align: left;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  color: #facd2f;
  margin-top: 100px;
  display: block;
}
.about h2 {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 700;
  font-size: 46px;
  line-height: 63px;
  margin-bottom: 20px;
  color: #000000;
}
.about p {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;

  color: #777c92;
}
.about .btn-xl {
  margin-top: 30px;
}
.about .wrap .img:nth-child(2) {
  margin-top: 100px;
}
/* about  */

/* expert  */
.expert {
  background-image: url(/static/media/contact.a00cc50b.png);
  background-color: #c4c4c4;
  background-size: cover;
  padding: 100px;
}
.expert h3 {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 700;
  font-size: 46px;
  line-height: 63px;
  color: #000000;
}
.expert p {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: #000842;
}
.expert .btn-xl {
  margin-top: 30px;
}
/* expert  */

/* footer  */
footer {
  background-size: cover;
  background-image: url(/static/media/footer.edd65210.png),
    linear-gradient(to right, #072387, #072387);
  padding: 100px 100px 0px;
}
footer h4 {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #ffffff;
}
footer ul li {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 188%;
  display: flex;
  align-items: center;
  color: #ffffff;
}
footer li a {
  color: #fff;
  text-decoration: none;
}
footer li a:hover {
  color: #fff;
}
footer .copy-right {
  border-top: 1px solid #fff;
  padding: 28px 5px;
}
footer .copy-right p {
  font-family: 'Poppins';
  font-weight: 400;
  font-size: 16px;
  color: #ffffff;
}
/* footer  */

/*----------------------------------------- login ------------------------------------------- */
.limiter {
  width: 100%;
  margin: 0 auto;
}

.container-login100 {
  width: 100%;
  min-height: 100vh;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  background: #f2f2f2;
}

.wrap-login100 {
  width: 100%;
  background: #fff;
  overflow: hidden;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  flex-direction: row-reverse;
}

/*==================================================================
  [ login more ]*/
.login100-more {
  width: calc(100% - 50%);
  background-repeat: no-repeat;
  background-size: cover !important;
  background-position: center;
  background: url(/static/media/login.90c932fe.png);
  position: relative;
  z-index: 1;
}

.login100-more::before {
  content: '';
  display: block;
  position: absolute;
  z-index: -1;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.1);
}

/*==================================================================
  [ Form ]*/

.login100-form {
  width: 50%;
  min-height: 100vh;
  display: block;
  background-color: #ffffff;
  padding: 100px 140px;
}

.login100-form-title {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 600;
  font-size: 33px;
  line-height: 48px;
  color: #000000;
  text-transform: capitalize;
}
.limiter form p {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #000000;
}
.limiter .wrap-input100 input {
  padding: 0px 23px 7px;
}

.limiter .wrap-input100 .input .icon-message {
  background: url(/static/media/message.2f17041b.svg) no-repeat;
  display: block;
  width: 25px;
  height: 25px;
  background-position: 5px 1px;
  position: absolute;
  bottom: 4px;
  left: -6px;
}
.limiter .wrap-input100 .input .icon-lock {
  background: url(/static/media/padlock.217bd91f.svg) no-repeat;
  display: block;
  width: 25px;
  height: 25px;
  background-position: 5px 1px;
  position: absolute;
  bottom: 5px;
  left: -6px;
}
.limiter .wrap-input100 .input .fa-eye-slash {
  position: absolute;
  right: 0;
  bottom: 8px;
  color: #ababab;
}

.limiter .wrap-input100 .input .fa-eye {
  position: absolute;
  right: 0;
  bottom: 8px;
  color: #ababab;
}

.input {
  position: relative;
}
.label-input100 {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 20px;
  color: #999999;
  margin-bottom: 10px;
}
.limiter .flex-sb-m {
  margin-top: 30px;
  font-family: 'Poppins';
  font-weight: 300;
  font-size: 12px;
}
.limiter .flex-sb-m a {
  color: #000;
}
.limiter .txt2 {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #b5b5b5;
  margin-top: 30px;
  margin-bottom: 32px;
  display: block;
}
.label-checkbox100 {
  font-size: 12px;
}

#email-validation {
  color: #b22d2a;
  position: absolute;
  font-size: small;
}

#pass-validation {
  color: #b22d2a;
  position: absolute;
  font-size: small;
}

#reset-pass-2 {
  margin-top: 3em;
}

#spinner-btn {
  margin-right: 6em;
}

#spinner-text {
  margin-left: 2.3em;
  margin-top: 0.2em;
  color: white;
  font-weight: bold;
  font-size: large;
}

#spinner-text-2 {
  margin-left: 2.5em;
  margin-top: 0.2em;
  color: white;
  font-weight: bold;
  font-size:medium;
}

#spinner-btn-2{
  margin-right: 4.5em;
}

#spinner-text-3 {
  margin-left: 2.8em;
  margin-top: 0.5em;
  color: white;
  font-weight: bold;
  font-size:medium;
}
/*----------------------------------------- login ------------------------------------------- */

/*----------------------------------------- basic-information ------------------------------------------- */
.basic {
  display: flex;
  font-family: 'Poppins';
}
.basic .sign-up {
  background: #072387;
  width: 600px;
  min-height: 100vh;
  color: #fff;
  padding: 20px 43px;
}
.basic .wrap {
  width: 100%;
}
.basic .sign-up h1 {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 600;
  font-size: 25px;
  line-height: 34px;
  text-transform: capitalize;
  margin-top: 53px;
}
.basic .sign-up .heading p {
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #e1e1e1;
}
.basic .sign-up ul {
  margin-top: 65px;
  margin-bottom: 25px;
}
.basic .sign-up li {
  list-style: none;
}
.basic .sign-up li p {
  display: inline-block;
  margin-left: 25px;
  opacity: 0.7;
}
.basic .sign-up li .active {
  opacity: 1;
}
.basic .sign-up .divider {
  height: 40px;
  width: 0.8px;
  background: #fff;
  margin-left: 13px;
  transition: all 800ms ease;
}
.basic .sign-up .node {
  height: 26px;
  width: 26px;
  border: 1px solid #fff;
  font-size: 12px;
  text-align: center;
  border-radius: 50%;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  transition: all 1000ms ease;
}
.basic .wrap {
  padding: 90px 71px;
}
.basic .wrap h2 {
  font-family: 'Open Sans';
  font-weight: 600;
  font-size: 30px;
  line-height: 41px;
  color: #000000;
  margin-bottom: 40px;
}
.basic .wrap .photo {
  width: 130px;
  height: 130px;
  background: #f5f5f5;
  border-radius: 50%;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 30px;
}
.basic .wrap .photo .pic .camera {
  position: absolute;
  width: 28px;
  height: 26.61px;
  bottom: 0;
  right: 5px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #0d9edf;
}
.basic .wrap .btn-xl {
  height: 53px;
  line-height: 14px;
}
.basic .wrap .left .txt {
  font-size: 12px;
  font-weight: 300;
}
.process .check {
  display: inline-block;
  transform: rotate(45deg);
  height: 11px;
  width: 6px;
  border-bottom: 3px solid #fbd95f;
  border-right: 3px solid #fbd95f;
}
.process .circle {
  background: rgb(255 255 255 / 34%);
  width: 20px;
  border-radius: 50%;
}
.login100-form .back {
  color: #000;
  font-family: 'Poppins';
  font-weight: 500;
}

/*----------------------------------------- basic-information ------------------------------------------- */

/*----------------------------------------- other-details ------------------------------------------- */
.details .suggestion {
  font-size: 12px;
  font-weight: 500;
  color: #8e90a1;
  margin-top: 10px;
}
.details .suggestion span {
  border: 1px solid #8e90a1;
  border-radius: 7px;
  padding: 2px 10px;
  margin-right: 10px;
  display: inline-flex;
  margin: 2px;
}
.details .input span {
  color: #ff5757;
  font-size: 12px;
  font-weight: 400;
  position: absolute;
  right: 0;
  display: flex;
  bottom: 2px;
  cursor: pointer;
}
.details .input span .close {
  border-radius: 50%;
  padding: 0.5em;
  width: 16px;
  height: 16px;
  border: 2px solid #ff5757;
  color: #ff5757;
  position: relative;
  margin-right: 5px;
}

.details .input span .close::before {
  content: ' ';
  position: absolute;
  display: block;
  background-color: #ff5757;
  width: 1px;
  left: 5px;
  top: 3px;
  bottom: 3px;
  transform: rotate(45deg);
}
.details .input span .close::after {
  content: ' ';
  position: absolute;
  display: block;
  background-color: #ff5757;
  height: 1px;
  top: 5px;
  left: 3px;
  right: 4px;
  transform: rotate(45deg);
}
.details .add {
  margin-top: 14%;
}
.details .add p {
  font-size: 16px;
  font-weight: 600;
  color: #0d9edf;
  text-align: end;
}
.details .btn-outline {
  margin-right: 30px;
}
.modal {
  background: rgb(255, 255, 255 / 67%);
}
/*----------------------------------------- other-details ------------------------------------------- */

/*----------------------------------------- Dashboard ------------------------------------------- */

.timeout-confirm {
  margin-top: 10px;
  background-color: #235feb;
  color: white;
  width: 100px;
}

.timeout-div {
  margin-top: 100px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.day-filter {
  font-weight: 500;
  color: #757575;
}

.dashboard_section {
  min-height: 100vh;
}

.side_bar_wrapper {
  background-color: #072387;
  width: 270px;
  position: fixed;
  left: 0;
  top: 0;
  height: 100%;
}

.darkHeader {
  box-shadow: 0px 2px 10px -1px rgba(0, 0, 0, 0.2);
  -webkit-box-shadow: 0px 2px 10px -1px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 0px 2px 10px -1px rgba(0, 0, 0, 0.2);
}

.dashboard_main_section {
  background-color: #f4f7fc;
}

.dashboard_header {
  padding: 18px 36px 18px 60px;
  background-color: #f4f7fc;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  position: -webkit-sticky;
  position: -moz-sticky;
  position: -ms-sticky;
  position: -o-sticky;
  position: sticky;
  top: 0px;
  z-index: 99;
  transition: all 0.5s;
}

.dashboard_header .header_menu {
  margin: 0px -15px;
}

.dashboard_header .header_menu_item {
  padding: 0px 15px;
  position: relative;
  cursor: pointer;
}

.dashboard_header .user-img {
  width: 45px;
  height: 45px;
  border-radius: 50%;
  overflow: hidden;
  border: 2px solid #0d9edf;
}
.dashboard_header .admin {
  background: #fff;
  position: absolute;
  right: 6px;
  top: 43px;
  width: 200px;
  box-shadow: 0px 5px 24px rgba(0, 0, 0, 0.1);
  border-radius: 20px;
  padding: 15px;
}
.dashboard_header .admin ul {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 22px;
  color: #072387;
}
.dashboard_header .admin ul li {
  padding: 10px;
  border-bottom: 1px solid #e1e1e1;
}
.dashboard_header .admin ul li:last-child {
  border: none;
  padding-bottom: 0px;
}
.dashboard_header .admin ul li a {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 22px;
  color: #072387;
}

.dashboard_header .user-img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  vertical-align: middle;
}

.dashboard_header .user-name {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #000842;
  padding-left: 10px;
}

.dashboard_header h1 {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 600;
  font-size: 26px;
  line-height: 35px;
  color: #000000;
  margin-bottom: 0px;
}

.dashboard_header ul li {
  list-style: none;
}

.dashboard_main_section {
  width: calc(100% - 270px);
  margin-left: auto;
}

.dashboard_section .logo-main {
  padding: 30px 15px 38px 40px;
}

.dasboard_main_area {
  padding: 40px 36px 70px 60px;
}

.dashboard_container {
  max-width: 900px;
}

.dashboard_container_points {
  max-width: 100%;
}

.dashboard_main_section .counter_title {
  margin-bottom: 20px;
}

.dashboard_main_section .counter_title h3 {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 600;
  font-size: 22px;
  line-height: 30px;
  color: #000000;
  margin-bottom: 0px;
}

.counter-card-row {
  margin: 0px -20px 50px -20px;
}

.counter-card-row .counter-cards {
  padding: 0px 20px;
}

.counter-card-row .counter-cards .inner-column {
  width: 100%;
  min-height: 138px;
  background-color: #fff;
  border-radius: 10px;
}

.counter-card-row .inner-column {
  display: flex;
  align-items: center;
  padding: 28px;
}

.counter-card-row .counter-label h4 {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 25px;
  color: #000000;
}

.counter-card-row select {
  -webkit-appearance: none;
  appearance: none;
  border: none;
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 20px;
  color: #808080;
  background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABMAAAASCAYAAAC5DOVpAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAABoSURBVHgB7Y6xDYBACABfXEQ3YQRHsHQLZARHcBQ6SzdQN5HCiiCxMfmCa/5zgQulJMn/NJ4kokOfLtg7mbm3El6GlxIAALPnW0+KyIaIl34HJzTq5evn2BPcbTAKhTETRA1NUSipgBt2ixysRo8E5QAAAABJRU5ErkJggg==);
  background-size: 18px;
  background-repeat: no-repeat;
  background-position: 100% center;
  width: 110px;
}

.counter-card-row .counter-label {
  flex-grow: 1;
}

#report-export{
  background-color: rgba(13,158,223,255);
  border-color: rgba(13,158,223,255);
  color:#ffffff;
}

#view-button{
  color: rgba(13,158,223,255);
  cursor: pointer;

}

.counter-card-row .counter-filter {
  flex-grow: 1;
}

.counter-card-row .counter-card-img {
  flex: 0 0 25%;
  max-width: 25%;
}

.counter-card-row .counter-card-img img {
  width: 100%;
}

.counter-card-row .counter-card-content {
  flex: 0 0 75%;
  max-width: 75%;
}

.counter-card-row .card-inner-cols {
  padding: 0px 10px;
}

.counter-card-row .counter-val-main {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-size: 35px;
  color: #000000;
}

.counter-card-row .counter-val-main span {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  color: #000000;
  position: relative;
}

.counter-card-row .counter-val-main span::after {
  content: '';
  width: 16px;
  height: 16px;
  background-position: center;
  background-size: contain;
  position: absolute;
}

.counter-card-row .counter-val-main .up::after {
  background: url(/static/media/up-arrow-icon.adb931a6.svg);
  right: -20px;
  top: 2px;
  background-repeat: no-repeat;
}

.new-notification {
  position: relative;
  cursor: pointer;
}


.notification-close {
  display: none;
}
.notification-open {
  display: inline;
  position: absolute;
  right: 16%;
  top: 34px;
  background: #ffffff;
  box-shadow: 0px 5px 24px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  width: 440px;
  border-radius: 20px;
}
.notification-box .heading h5 {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
}
.notification-box .heading h5 span {
  color: #999999;
  font-size: 12px;
  margin-left: 7px;
}
.notification-box .read h5 {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
}
.notification-box .top {
  margin-bottom: 10px;
  padding: 12px 16px;
}
.notification-box .user {
  display: flex;
  border-bottom: 1px solid #e1e1e1;
  padding: 12px 16px;
  position: relative;
}
.notification-box .user .img {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  overflow: hidden;
  background-color: #000;
  margin-right: 16px;
  display: flex;
  justify-content: center;
}
.notification-box .user .active {
  width: 8px;
  height: 8px;
  background: #90cdf4;
  border-radius: 50%;
  position: absolute;
  left: 10px;
  top: 7px;
}
.notification-box .user .content {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
}
.notification-box .user .content .day {
  color: #999999;
  margin-top: 16px;
}

.counter-card-row .counter-val-main .down::after {
  background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAwAAAARCAYAAADpPU2iAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAC5SURBVHgB3ZHBEQFBEEXfqglgUxACVQ5uZCIDIbARkAGZkABCsCE4cmB0D6PGbLPl6ldN9VT/7un+f8DAHrwei+vwI/6hwR1gKrFMk9Ee4WZZfe1uUBQwt17zWV7qJoVexPONhBHfUfehGzRcoWop5gJjjaFhAFsZv/xULNx6KBOeaz0gAkshjmQG6Cr6emx42dqDkzdWU0Ni8duEiMyAIDTlGx+XGhCFtmIHCzkri3NWUsZW56b4gDtfaSrRoT3+hQAAAABJRU5ErkJggg==);
  right: -20px;
  top: 2px;
  background-repeat: no-repeat;
}

.side_navigation {
  padding-bottom: 20px;
}

.side_nav .side_nav_item {
  padding-bottom: 10px;
  list-style: none;
}

.side_nav .side_nav_item:last-child {
  padding-bottom: 0px;
}

.side_nav .side_nav_item a {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 22px;
  color: rgba(255, 255, 255, 0.7);
  display: block;
  padding: 11px 15px 11px 60px;
  transition: all 0.5s;
  position: relative;
}

.side_nav .selected a {
  color: rgba(255, 255, 255, 1);
}

.side_bar .side_nav .selected a::before {
  opacity: 1;
}

.side_nav .side_nav_item a::before {
  content: '';
  width: 16px;
  height: 16px;
  opacity: 0.7;
  position: absolute;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  left: 38px;
  top: 50%;
  transform: translateY(-50%);
  transition: all 0.5s;
}

.side_nav .selected {
  background-color: #3f42a8;
  border-right: 7px solid #0d9edf;
}

.side_nav .side_nav_item:hover a::before {
  opacity: 1;
}

.dashboard_header .mob-btn {
  display: none;
}

/**side nav icons urls**/
.side_nav .dasboard_item a::before {
  background-image: url(/static/media/dashbaord-icon.18aae9e9.svg);
}

.side_nav .question_item a::before {
  background-image: url(/static/media/question-icon.dee1f89e.svg);
}

.side_nav .points_item a::before {
  background-image: url(/static/media/points-icon.d116522f.svg);
}

.side_nav .experts_list_item a::before {
  background-image: url(/static/media/list-exp-icon.60a081e7.svg);
}

.side_nav .user-manage_list_item a::before {
  background-image: url(/static/media/manage-user-icon.26371660.svg);
}

.side_nav .faq_item a::before {
  background-image: url(/static/media/faq-icon.50a30d85.svg);
}

.side_nav .account-settings_item a::before {
  background-image: url(/static/media/account-setings-icon.326ec083.svg);
}

.side_nav .pay-manage_item a::before {
  background-image: url(/static/media/pay-manage-icon.cab7fcb5.svg);
}

.side_nav .badge-manage_item a::before {
  background-image: url(/static/media/badge-manage-icon.45d141bc.svg);
}

.side_nav .reports_item a::before {
  background-image: url(/static/media/resports-icon.12289e37.svg);
}
.side_nav .legal_setting a::before {
  background-image: url(/static/media/legal-setting.3ae1a31e.svg);
}
/**side nav icons urls**/
/**side nav icons urls**/

.side_nav .side_nav_item:hover a {
  color: rgba(255, 255, 255, 1);
}

.close-btn {
  display: none;
}

/*----------------------------------------- Dashboard ------------------------------------------- */

/*----------------------------------------- Dashboard list of experts ------------------------------------------- */

.list-experts-area {
  padding: 60px 36px 60px 60px;
}

.list-experts-main .tab-navigation {
  list-style: none;
  display: flex;
  border-bottom: 1px solid #d6d6d6;
  width: 100%;
  margin-bottom: 25px;
}

.list-experts-main .nav li:first-child .nav-link {
  padding-left: 0px;
}

.list-experts-main .tab-navigation li {
  padding: 10px 15px;
  position: relative;
}

.list-experts-main .tab-navigation .activate::before {
  content: '';
  width: 100%;
  height: 2px;
  background-color: #0d9edf;
  position: absolute;
  bottom: -1px;
  left: 0px;
}

.list-experts-main .tab-navigation .activate a {
  color: #000;
  font-weight: 500;
}
.list-experts-main .nav-link {
  color: #808080;
}
.list-experts-main .nav .nav-item {
  position: relative;
}

.list-experts-main .nav li:first-child::after {
  display: none;
}

.list-experts-main .nav li::after {
  content: '';
  width: 1px;
  height: 20px;
  background-color: #808080;
  position: absolute;
  left: 0px;
  top: 50%;
  transform: translateY(-50%);
}

.list-experts-main .nav .nav-item {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 27px;
  color: #808080;
}
.nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link.active {
  color: #000;
  background-color: transparent;
  border-color: transparent;
  border-bottom: 2px solid #0d9edf;
}
.table-exp-main {
  border: 1px solid #e3e7eb;
  box-sizing: border-box;
  border-radius: 6px 6px 0px 0px;
  background-color: #fff;
  /* overflow-x: auto; */
}

.admin-user-table-section {
  overflow-x: auto;
}

.table-exp-main .experts-table-filter {
  padding: 13px 28px;
  text-align: end;
  position: relative;
  width: 100%;
  grid-gap: 1em;
}

/* #user-mgmt-dropdown{
  width: 120px;
} */

#delete-modal{
  display: flex;
  flex-direction: row;
}

.table-exp-main .experts-table-filter img {
  vertical-align: inherit;
}

.table-exp-main .experts-table-filter span {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  color: #0d9edf;
  cursor: pointer;
  display: inline-block;
}

.manage-user-main{
  width: 100%;
  overflow-x: auto;
  max-height: 550px;
}

.list_experts_table thead th {
  background-color: #e1f2fa;
  border-top: 1px solid #e3e7eb;
  border-bottom: 1px solid #e3e7eb;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  /* border-collapse: collapse;   */

}

.list_experts_table thead tr {
  vertical-align: middle;
}

.list_experts_table thead th {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 16px;
  text-transform: uppercase;
  color: #000000;
  padding: 14px 15px;
  white-space: nowrap;
}

.list_experts_table tbody tr {
  border-bottom: 1px solid #e3e7eb;
}

.list_experts_table tbody tr:last-child {
  border-bottom: none;
}

.list_experts_table tbody td {
  padding: 10px 15px;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  color: #000000;
  vertical-align: middle;
  padding: 14px 15px;
  border: none;
  white-space: nowrap;
}

.list_experts_table tbody td:nth-child(7) .loc-name-val {
  /* position: relative; */
  width: 350px;
  overflow-x: auto;
}

.list_experts_table tbody td:nth-child(2) .question-text-val {
  /* position: relative; */
  width: 350px;
  overflow-x: auto;
}

.list_experts_table tbody td:nth-child(4) .location-text-val {
  /* position: relative; */
  width: 200px;
  overflow-x: auto;
}

/* .list_experts_table tbody td:nth-child(2) .exp-name-val::after {
  content: "";
  width: 1px;
  height: 30px;
  background-color: #b0b9c1;
  position: absolute;
  right: 50px;
  top: 50%;
  transform: translateY(-50%);
} */

.list_experts_table thead th:first-child,
.list_experts_table tbody td:first-child {
  width: 4%;
}

.list_experts_table thead th:not(:first-child),
.list_experts_table tbody td:not(:first-child) {
  width: 24%;
}

.table-exp-main .list_experts_table tbody {
  border-top: none;
}

.list_experts_table .exp-name img {
  vertical-align: baseline;
}

/* custom checkbox */
.input-box input[type='checkbox'] {
  height: 14px;
  width: 14px;
  margin: 0;
  padding: 0;
  opacity: 1;
  -webkit-appearance: none;
          appearance: none;
  border: 1px solid #000;
  border-radius: 3px;
  background: transparent;
  /* position: relative; */
  margin-right: 10px;
}

#apply-edit-question{
  margin-left: 10px;
}

.input-box input[type='checkbox']:checked {
  border: 1px solid #000;
  background: url(/static/media/check-mark-box.9c448f3e.svg) #072387;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 70%;
}

.input-box input[type='checkbox']:focus {
  outline: none;
}

/* custom checkbox */

.table-pagination-wrapper {
  padding: 20px 0px;
}

.table-pagination-wrapper .pagination-label {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  color: #808080;
  padding-right: 5px;
}

.table-pagination-wrapper .select-page-number {
  font-size: 12px;
  line-height: 15px;
  border-top: none;
  border-right: none;
  border-left: none;
  border-bottom: 1px solid #808080;
  outline: none;
  font-style: normal;
  font-weight: bold;
  color: #757575;
  appearance: none;
  -moz-appearance: none;
  -webkit-appearance: none;
  background: url(/static/media/pagination-drop-arrow.3fd9ea0e.svg);
  background-repeat: no-repeat;
  background-size: 10px;
  background-position: right center;
  width: 30px;
  margin-right: 20px;
}

.table-pagination-wrapper .pagination-controls a {
  font-size: 24px;
  font-weight: 500;
  color: #0d9edf;
  line-height: 18px;
}

.table-pagination-wrapper .page-counter {
  margin-right: 12px;
}

.table-pagination-wrapper .pagination-controls a:first-child {
  margin-right: 10px;
}

/***connection tab****/

.connect-area {
  background-color: #fff;
  border: 1px solid #e3e7eb;
  border-radius: 6px 6px 0px 0px;
  padding: 25px;
}
.list-experts-area .tabs_pannel {
  margin-top: 25px;
}

.connect-area .connection-img {
  flex: 0 0 8%;
  max-width: 8%;
}
.connect-area .connection-img .profile {
  width: 45px;
  height: 45px;
  border-radius: 50%;
  overflow: hidden;
  border: 2px solid #0d9edf;
}

.connect-area .connection-content {
  max-width: 92%;
  flex: 0 0 92%;
}

.connect-area .connect-name {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  color: #393939;
}

.connect-area .connect-date {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  line-height: 15px;
  color: rgba(0, 0, 0, 0.75);
}

.connect-area .connect-col {
  padding: 0px 15px;
}

.connect-area .connection-row {
  margin: 0px -15px;
  padding-bottom: 20px;
}

.connect-area .connection-row:last-child {
  padding-bottom: 0px;
}

.connect-area .connect-body {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  color: rgba(35, 35, 35, 0.75);
  padding: 5px 10px 10px 20px;
  position: relative;
  border-bottom: 0.5px solid #9f9f9f;
}

.connect-area .connection-row:last-child .connect-body {
  border-bottom: none;
}

.connect-area .connect-body::before {
  content: '';
  width: 12px;
  height: 12px;
  background-image: url(/static/media/connect-check-icon.3fae454a.svg);
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  position: absolute;
  left: 2px;
  top: 10px;
}

.connect-area .delivered::before {
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABIAAAALCAYAAAByF90EAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAEdSURBVHgBlVLLbcJAFNy1Ysm3UIJTQdKCz/6ErQBSQeIKYioAKjBUsMgf+UgJUAIdwNlf5qFdCRBgeBfP8+57M54xYy9UURR2lmV/uicspbQJG+yFatt2yjl/J5zn+VfXdb/67O3ZJWmaRhi0Pc8TpAxLJfpQCLF7elGSJEM8RlVVOdQ3TRND2SIIgpW+w/uWKPY1oHBdd0vKDMP4BBbn93oVqSULWgJfxuhHZVk6Ny/D+cGt92CfIZlYKwPe65Su65SaaZqSJJ8fqJi/4UtIRKQMvkTa3Ovimk0ZeIDs0LIsMnSD3lGfFCOhAxIL2Z26MBsD/2Aew0yGwQiLhngOKHbf9z/Yg7r4IcE+wfAccAb2JdT9AG/runZYTx0B+2WhCG7HLrAAAAAASUVORK5CYII=);
  width: 14px;
  height: 14px;
  top: 8px;
}

.connect-area .read::before {
  background-image: url(/static/media/read-check.5359b9e4.svg);
  width: 18px;
  height: 18px;
  top: 8px;
}

/**connection tab****/

/***request tab***/
.d-hover .hover span {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 11px;
  line-height: 16px;
  color: #ffffff;
  background: #8083a0;
  border-radius: 2px;
  position: absolute;
  top: -12px;
  left: 0;
  padding: 0px 5px;
}
.d-hover .hover {
  position: relative;
}
.d-hover .hover span {
  display: none;
}
.d-hover .hover:hover span {
  display: inline;
}
.complteted-badge {
  width: 82px;
  display: inline-block;
  padding: 4px 16px;
  border-radius: 4px;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  color: #089216;
  background-color: #d0ffd5;
  border: 1px solid rgba(8, 146, 22, 0.3);
  border-radius: 4px;
  text-align: center;
}

.rejected-badge {
  width: 82px;
  display: inline-block;
  padding: 4px 16px;
  border-radius: 4px;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  color: #e41818;
  background: #fad1d1;
  border: 1px solid rgba(255, 87, 87, 0.3);
  border-radius: 4px;
  text-align: center;
}

.block-badge {
  width: 82px;
  display: inline-block;
  padding: 4px 16px;
  border-radius: 4px;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  color: #8e90a1;
  background: #eeeaea;
  border: 1px solid rgba(142, 144, 161, 0.2);
  border-radius: 4px;
  text-align: center;
}

.request-area .act-title {
  width: 82px;
  text-align: center;
  display: inline-block;
}

/**custom select box**/
.select_container .dropholder {
  width: 100%;
  border-radius: 10px;
  font-size: 18px;
  color: transparent;
  position: relative;
  border: 1px solid #072387;
  border-radius: 4px;
  position: inherit !important;
  z-index: 9;
}

.filter-sort {
  width: 107px;
}

.select_container p {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  color: #072387;
  padding: 6px 10px;
}

.select_container .dropdown,
.select_container .dropholder {
  text-align: left;
}

.select_container .dropdown {
  width: 100%;
  cursor: pointer;
  position: relative;
  width: 100%;
  font-family: 'Poppins';
  font-weight: 500;
  font-size: 12px;
}

.select_container .dropdown:after {
  content: '';
  width: 6px;
  height: 6px;
  border-top: solid 6px #072387;
  border-left: solid 6px transparent;
  border-right: solid 6px transparent;
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  display: block;
  color: #072387;
}

.select_container .dropdown.active + .dropdownMenu {
  display: block;
}

.select_container .dropdownMenu {
  padding: 0;
  position: absolute;
  left: 0;
  top: 35px;
  right: 0;
  margin: 0;
  cursor: pointer;
  z-index: 5;
  overflow: hidden;
  background: #ffffff;
  box-shadow: 0px 5px 24px rgba(0, 0, 0, 0.1);
  border-radius: 0px 0px 4px 4px;
  padding: 7px 10px;
}

.select_container .dropholder option {
  padding: 7px 0px;
  list-style: none;
  color: #072387;
  transition: all 0.5s;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  border-bottom: 0.5px solid rgba(0, 0, 0, 0.1);
}

.select_container .dropholder option:hover {
  color: #0d9edf;
}

.select_container .dropholder option:last-child {
  border: none;
}

/**custom select box**/

/***request tab***/

/*----------------------------------------- Dashboard list of experts ------------------------------------------- */
/*----------------------------------------- Dashboard question ------------------------------------------- */
.my-question-section {
  background-color: #fff;
}

.my-question-section .myquestion-row {
  padding-bottom: 10px;
}

.my-question-section .myquestion-row .question-label,
.my-question-section .myquestion-details .location {
  max-width: 9%;
  flex: 0 0 9%;
  white-space: nowrap;
}

.my-question-section .myquestion-row .my-question,
.my-question-section .myquestion-details .date {
  max-width: 71%;
  flex: 0 0 71%;
}

.my-question-section .myquestion-row .my-question-status,
.my-question-section .myquestion-details .response-counter {
  max-width: 20%;
  flex: 0 0 20%;
}

.my-question-section .my-question-item {
  background-color: #fff;
  padding: 15px 25px;
  border-top: 1px solid #e3e7eb;
  border-bottom: 1px solid #e3e7eb;
}

.my-question-section .my-question-item:last-child {
  margin-bottom: 45px;
}

.my-question-section .my-question-item .myquestion-row p {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  color: #000000;
}

.my-question-section .my-question-item .myquestion-row .question-label p,
.my-question-section .myquestion-row .my-question-status p {
  font-weight: 600;
}

.my-question-section .my-question-item .open p {
  color: #0d9f36;
}

.my-question-section .my-question-item .close p {
  color: #e23737;
}

.myquestion-details .question-details-col img {
  vertical-align: baseline;
  margin-right: 5px;
}

.myquestion-details .question-details-col p {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  color: #b6b6b6;
}

.questions-main-section {
  padding: 30px 36px 60px 60px;
}

.questions-main-section .add-new-question {
  padding-bottom: 25px;
}

.questions-main-section .add-new-question .btn {
  width: 228px;
  height: 52px;
  padding: 13px 20px 13px 50px;
  font-style: normal;
  font-weight: 500;
  font-size: 17px;
  line-height: 26px;
  position: relative;
}

.inner-filter .btn {
  height: 43px;
  width: 103px;
  line-height: 40px;
  padding: 0;
  font-size: 15px;
}
.experts-table-filter .question-filter-box {
  padding: 25px 25px 30px 25px;
  width: 390px;
  position: absolute;
  background: #fff;
  right: 100px;
  box-shadow: 0px 5px 24px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
}

.experts-table-filter .filter-logo {
  padding-left: 30px;
}

.add-question .btn-xl {
  height: 53px;
  width: 129px;
  padding: 0;
}
.add-question .add-icon {
  position: absolute;
  right: -50px;
  top: 35px;
  cursor: pointer;
}
.add-question a {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 22px;
  text-transform: uppercase;
  color: #0d9edf;
}
.modal-title.h4 {
  margin-left: 30px;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 27px;
  color: #000000;
}
.modal-header {
  border-bottom: 0px;
}

.questions-main-section .plus-btn::before {
  content: '';
  width: 19px;
  height: 19px;
  /* background: url('../img/plus-icon.svg'); */
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  position: absolute;
  left: 25px;
  top: 50%;
  transform: translateY(-50%);
}

.date-range-feild-wrapper {
  padding-bottom: 25px;
}

.date-range-field {
  flex-wrap: wrap;
  align-items: center;
}

.date-range-field .range-text {
  padding: 0px 4px;
  max-width: 10%;
  flex: 0 0 10%;
  text-align: center;
}

.date-range-field .range-text span {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 20px;
  color: #999999;
}

.date-range-field .date-range {
  flex: 0 0 45%;
  max-width: 45%;
}

.date-range-feild-wrapper input {
  border: 1px solid rgba(0, 8, 66, 0.5);
  box-sizing: border-box;
  border-radius: 6px;
  padding: 8px;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #000842;
}

.field-label {
  text-align: left;
}

.field-label label {
  margin-bottom: 7px;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 20px;
  color: #999999;
}

/*----------------------------------------- Dashboard question ------------------------------------------- */

.contact-us .form-control {
  border: 1px solid rgba(0, 8, 66, 0.5);
  box-sizing: border-box;
  border-radius: 6px;
}
.account .profile-pic .img {
  width: 132px;
  height: 128px;
  background: #e1e1e1;
  border-radius: 50%;
  overflow: hidden;
  margin: auto;
  display: flex;
  justify-content: center;
  align-items: center;
}

.account .profile-pic .btn-outline {
  font-family: 'Poppins';
  font-weight: 500;
  font-size: 15px;
  line-height: 22px;
  color: #0d9edf;
  cursor: pointer;
  padding: 15px 17px;
  display: block;
  width: 157px;
  margin: auto;
}
.account .text-end .btn-xl {
  width: 153px;
  height: 52px;
  padding: 0;
  line-height: 54px;
}

.account .text-end .btn-xl{
  width: 153px;
  height: 52px;
  padding: 0;
  line-height: 54px;
 background-color: #089216;
 color: #0d9f36;
}
.account .addcard p {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 22px;
  text-transform: capitalize;
}
.account .form-control {
  background: #ffffff;
  border: 1px solid rgba(0, 8, 66, 0.5);
  box-sizing: border-box;
  border-radius: 6px;
}
.account .notification p {
  display: flex;
  font-family: 'Poppins';
  font-weight: 500;
  font-size: 14px;
}
.account .notification form {
  margin-left: 20%;
}
.cards .form-control {
  background: #ffffff;
  border: 1px solid rgba(0, 8, 66, 0.5);
  box-sizing: border-box;
  border-radius: 6px;
}
.cards .btn {
  height: 53px;
  width: 129px;
}
.cards .btn-xl {
  line-height: 14px;
  margin-left: 19px;
}
.cards .cards-img {
  position: absolute;
  right: 7px;
  bottom: 7px;
}
.cards span.MuiTypography-root {
  font-size: 18px;
  font-family: 'Poppins';
  font-weight: 500;
}
.cards .cvv {
  position: relative;
}
.account .form-select {
  border: 1px solid rgba(0, 8, 66, 0.5);
  border-radius: 6px;
}
.account form span {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 18px;
  text-align: right;
  color: #0d9edf;
  display: block;
  margin-top: 4px;
  cursor: pointer;
}
.account .txt {
  font-family: 'Open Sans';
  font-weight: 600;
  font-size: 18px;
}
.account .add p {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.faq h4 {
  font-family: 'Poppins';
  font-weight: 500;
  font-size: 18px;
  line-height: 27px;
}
.faq .form-control {
  width: 303px;
  height: 42px;
  background: #ffffff;
  border: 1px solid rgba(0, 8, 66, 0.5);
  box-sizing: border-box;
  border-radius: 100px;
  margin-top: 25px;
  padding: 0px 31px;
}
.faq .fa-search {
  position: absolute;
  top: 14px;
  left: 11px;
  cursor: pointer;
  color: #617798;
}
.txt3,
.accordian p {
  font-family: 'Poppins';
  font-weight: 400;
  font-size: 14px;
  line-height: 180%;
}
.MuiAccordion-region {
  background: #e1e1e1;
}
.tool {
  position: relative;
}
.tool:hover .tool_tip_main {
  display: inline-block;
}
.tool_tip_main {
  position: absolute;
  top: -25px;
  left: 10px;
  display: none;
}
.tool_tip {
  padding: 5px 5px;
  display: inline-block;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 11px;
  line-height: 16px;
  color: #ffffff;
  background-color: #8083a0;
  border-radius: 2px;
  position: relative;
  width: 120px;
}
/*----------------------------------------- Single Question---------------------------------------------- */

.single-question-section .my-question-item {
  border: 1px solid #e3e7eb;
}
.single-question .back-btn-main {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 22px;
  text-transform: uppercase;
  color: #0d9edf;
}

.single-question-section .my-question-item:last-child {
  margin-bottom: 10px;
}

.single-question .back-btn-main {
  padding-bottom: 15px;
}

.my-question-status .select_container .dropholder {
  width: 65%;
  margin-left: auto;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
}

.my-question-status .select_container .green-color {
  color: #0d9f36;
}

.my-question-status .select_container .red_color {
  color: #e23737;
}

.responses-wrapper {
  background: #ffffff;
  border: 1px solid #e3e7eb;
  border-radius: 2px;
  padding: 15px 25px;
}

.response-item .response-content p {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  color: #000000;
}

.response-item .response-content {
  padding-bottom: 14px;
}

.response-details .response-user span {
  display: inline-block;
}

.response-details .response-user {
  display: flex;
  align-items: center;
}

.response-details .response-user .response-img {
  width: 25px;
  height: 25px;
  /* background-image: url('../img/res-user-img-1.png');
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain; */
  border-radius: 50%;
  background: #e1e1e1;
  overflow: hidden;
  display: grid;
  place-content: center;
}

.response-details .response-user .response-user-name a {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  color: #b6b6b6;
  padding: 0px 20px 0px 8px;
  position: relative;
}

.response-details .response-user .response-user-name a::after {
  content: '';
  width: 1px;
  height: 15px;
  background-color: #b6b6b6;
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
}

.response-details .response-user .poc {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #000000;
}

.response-ctrls ul {
  list-style: none;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-end;
  cursor: pointer;
}

.response-ctrls .response-list li {
  padding: 0px 10px;
  position: relative;
}

.response-ctrls .response-list li span {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  color: #000000;
}

.response-ctrls .response-list .comment span {
  background: #f2f2f2;
  border-radius: 3px;
  display: block;
  padding: 2px 15px;
}

.response-ctrls .response-list .like img {
  margin-left: 4px;
  margin-top: -6px;
}

.response-ctrls .response-list .upvote img,
.response-ctrls .response-list .respond img,
.response-ctrls .response-list .spam img {
  margin-right: 5px;
  margin-top: -5px;
}

.response-details .response-list li a {
  position: relative;
}

.response-details .response-list li a::after {
  content: '';
  width: 1px;
  height: 15px;
  background-color: #b6b6b6;
  position: absolute;
  right: -10px;
  top: 50%;
  transform: translateY(-50%);
}

.response-details .response-list li:last-child a::after {
  display: none;
}

.responses-wrapper .response-item {
  padding-bottom: 15px;
  border-bottom: 1px solid #b6b6b6;
  margin-bottom: 10px;
}

.responses-wrapper .response-item:last-child {
  padding-bottom: 0px;
  border-bottom: none;
  margin-bottom: 0px;
}

.respond-text-area {
  padding-top: 17px;
}

.respond-text-area .text-box {
  position: relative;
}

.respond-text-area textarea {
  min-height: 95px;
  width: 100%;
  resize: none;
  padding: 10px;
  border: 1px solid #b6b6b6;
  font-size: 14px;
  line-height: 21px;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
}

.respond-text-area textarea::placeholder {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  color: #999999;
}

.respond-text-area .up-load-btn {
  width: 95px;
  height: 20px;
  display: inline-block;
  position: absolute;
  right: 10px;
  bottom: 20px;
}

.respond-text-area .up-load-btn input {
  display: none;
}

.respond-text-area .up-load-btn label {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  text-align: right;
  -webkit-text-decoration-line: underline;
          text-decoration-line: underline;
  color: #9b9b9b;
}

.respond-text-area .up-load-btn label img {
  margin-right: 6px;
}

.respond-text-area .submit-btn {
  margin-top: 15px;
}
.respond-btn-main .btn {
  box-shadow: none;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  padding: 10px 20px;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  text-transform: capitalize;
  line-height: 1.5;
  height: auto;
}
.respond-btn-main .btn:first-child {
  margin-right: 20px;
}

.respond-text-area .btn-outline {
  margin-right: 25px;
}
/* reply  */
.question-respnose-list li:first-child::before {
  content: '';
  width: 1px;
  height: 15px;
  background-color: #b6b6b6;
  position: absolute;
  left: 1px;
  top: 59%;
  transform: translateY(-50%);
}
.response-list.question-respnose-list li::after {
  content: '';
  width: 1px;
  height: 15px;
  background-color: #b6b6b6;
  position: absolute;
  right: 1px;
  top: 59%;
  transform: translateY(-50%);
}
.response-list.question-respnose-list li:last-child:after {
  display: none;
}
/* reply  */

/*----------------------------------------- Single Question---------------------------------------------- */
/*----------------------------------------- points dashboard ------------------------------------*/
.points .coupon {
  display: flex;
  background: #fff;
  padding: 10px;
  margin-bottom: 30px;
}
.points .coupon .coupon-code h3 {
  font-family: 'Open Sans';
  font-weight: 700;
  font-size: 18px;
  line-height: 25px;
  color: #000000;
  margin: 0;
}
.points .coupon .coupon-code span {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-size: 10px;
  line-height: 15px;
  color: #959191;
}
.points .coupon .coupon-code {
  position: relative;
  padding: 0px 15px;
  margin-right: 15px;
}
.points .coupon .coupon-code::after {
  content: '';
  width: 1px;
  height: 43px;
  background-color: #b6b6b6;
  position: absolute;
  right: 1px;
  top: 39%;
  transform: translateY(-50%);
}
.points .coupon .coupon-content {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 40px;
  color: #000000;
}
.points-counter {
  background-color: #fff;
  border: 1px solid #e3e7eb;
  padding: 15px 20px;
  flex-wrap: wrap;
  margin-bottom: 10px;
}

.points-counter .btn {
  padding: 10px 15px;
  line-height: 1.5;
  height: auto;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  box-shadow: none;
}

.points-counter .btn-outline {
  margin-right: 25px;
}

.points-available > * {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  color: #000000;
}

.points-available .points-val {
  margin-left: 20px;
}

.points-table-section {
  border-radius: 6px 6px 0px 0px;
  background-color: #fff;
}

.points-table-section .sort-filter-wrap {
  width: 107px;
  margin-left: auto;
}

.point-sort-filter {
  padding: 10px 25px;
}

.points-tb-wrapper {
  width: 100%;
}

.points-tb-wrapper table {
  width: 100%;
}

.points-tb-wrapper table th {
  vertical-align: middle;
  background-color: #e1f2fa;
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 16px;
  text-transform: uppercase;
  color: #000000;
  padding: 14px 40px;
  border-bottom: 1px solid #e3e7eb;
  border-top: 1px solid #e3e7eb;
}

.points-table .action-cell {
  width: 40%;
  margin-left: auto;
  text-align: center;
}

.points-table tbody td {
  padding: 14px 40px;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  color: #000000;
}

.points-table tbody tr {
  border-bottom: 1px solid #e3e7eb;
  border-top: 1px solid #e3e7eb;
}

.points-table tbody tr:last-child {
  border-bottom: none;
  border-top: none;
}

.points-table tbody td .action-cell a {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  -webkit-text-decoration-line: underline;
          text-decoration-line: underline;
  color: #0d9edf;
}

/*----------------------------------------- points dashboard ------------------------------------*/
/*----------------------------------------- Redeem Points ------------------------------------*/
.points-area .points-available {
  padding-bottom: 30px;
}

.points-area .dashboard_container_points .new-question-from-wrapper {
  max-width: 40%;
}

.field-wrapper input {
  background: #ffffff;
  border: 1px solid rgba(0, 8, 66, 0.5);
  box-sizing: border-box;
  border-radius: 6px;
  padding: 10px;
  font-size: 16px;
  line-height: 24px;
}

.field-wrapper input::placeholder {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #000842;
  opacity: 0.5;
}
.back-btn-main {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 22px;
  text-transform: uppercase;
  color: #0d9edf;
}

.field-wrapper .info-tooltip-main {
  position: absolute;
  top: 44px;
  right: -36px;
  cursor: pointer;
}

.field-wrapper .info-tooltip-main .tool-tip-inner {
  position: relative;
}

.field-wrapper .info-tooltip-main .tool-tip-box {
  position: absolute;
  left: 0;
  top: -40px;
  width: 324px;
  max-width: 324px;
  display: inline-block;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 11px;
  line-height: 16px;
  color: #ffffff;
  background: #8083a0;
  border-radius: 2px;
  padding: 4px 6px;
  display: none;
}

.field-wrapper .info-tooltip-main:hover .tool-tip-box {
  display: block;
}

.field-wrapper .info-tooltip-main .tool-tip-box::after {
  content: '';
  width: 5px;
  height: 5px;
  border-top: solid 5px #8083a0;
  border-left: solid 5px transparent;
  border-right: solid 5px transparent;
  position: absolute;
  left: 4px;
  bottom: -4px;
}

.submit-main .btn-xl {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
}
.submit-wrapper .btn-xl {
  height: 53px;
  line-height: 16px;
  color: #fff;
  font-weight: 500;
  width: 129px;
  padding: 0;
}
.submit-wrapper .btn-outline {
  margin-right: 30px;
}
.redeem .txt2 p {
  font-family: Poppins;
  font-size: 18px;
  font-weight: 500;
  line-height: 27px;
  letter-spacing: 0em;
  text-align: center;
}
/*----------------------------------------- Redeem Points ------------------------------------*/

@media (max-width: 1024px) {
  /*----------------------------------------- Dashboard ------------------------------------------- */
  span.mob-btn {
    display: block;
  }
  .dashboard_header h1 {
    margin-left: 33px;
  }
  .counter-card-row .counter-cards {
    flex: 0 0 100%;
    max-width: 100%;
    padding: 0px 15px 20px 15px;
  }

  .counter-card-row {
    margin: 0px -20px 30px -20px;
  }

  .dashboard_main_section {
    width: 100%;
    min-height: 100vh;
    margin: auto;
  }

  .side_bar_wrapper {
    transform: translateX(-100%);
    transition: all 0.5s;
    z-index: 9999;
    width: 100%;
  }

  .dashboard_header .mob-btn {
    margin-right: 10px;
    cursor: pointer;
    display: inline-block;
  }

  .dashboard_header {
    padding: 18px 25px 18px 25px;
  }

  .dasboard_main_area {
    padding: 40px 25px 70px 25px;
  }

  .dashboard_section .logo-main {
    padding: 70px 20px 40px 20px;
    text-align: center;
  }

  .logo-main {
    position: relative;
  }

  .close-btn {
    display: inline-block;
    color: #fff;
    font-size: 32px;
    position: absolute;
    right: 30px;
    top: 10px;
    cursor: pointer;
  }

  .side_bar {
    width: 100%;
  }

  .side-bar-active {
    transform: translateX(0) !important;
    position: fixed;
    width: 100%;
    background: #072387;
    transition: all 0.5s;
    z-index: 9999;
    height: 100%;
  }
  .field-wrapper .info-tooltip-main {
    position: absolute;
    top: 44px;
    right: 10px;
    cursor: pointer;
  }

  /*----------------------------------------- Dashboard ------------------------------------------- */
}

@media screen and (max-width: 1167px) and (min-width: 992px) {
  .login100-form {
    padding: 63px 89px;
  }
}
@media (max-width: 1299px) {
  /*----------------------------------------- Dashboard list of experts ------------------------------------------- */
  .list_experts_table tbody td:nth-child(2) .exp-name-val::after {
    right: 20px;
  }

  /**connect tab***/
  .connect-area .connection-img {
    flex: 0 0 10%;
    max-width: 10%;
  }

  .connect-area .connection-content {
    max-width: 90%;
    flex: 0 0 90%;
  }

  /**connect tab***/

  /*----------------------------------------- Dashboard list of experts ------------------------------------------- */

  /*----------------------------------------- Dashboard question ------------------------------------------- */
  .my-question-section .myquestion-row .question-label,
  .my-question-section .myquestion-details .location {
    max-width: 14%;
    flex: 0 0 14%;
  }

  .my-question-section .myquestion-row .my-question,
  .my-question-section .myquestion-details .date {
    max-width: 66%;
    flex: 0 0 66%;
  }

  .my-question-section .myquestion-row .my-question-status,
  .my-question-section .myquestion-details .response-counter {
    max-width: 20%;
    flex: 0 0 20%;
  }

  /*----------------------------------------- Dashboard question ------------------------------------------- */
}
@media (min-width: 992px) {
  header.masthead {
    height: 100vh;
    min-height: 40rem;
    padding-top: 4.5rem;
    padding-bottom: 0;
  }
}
@media (max-width: 992px) {
  nav .navbar-brand img {
    width: 80px;
  }
  .navbar .navbar-toggler span:target-within {
    background: #000000;
  }
  .about,
  .expert {
    padding: 50px 20px;
  }
  .about h2 {
    font-size: 35px;
    line-height: 50px;
  }

  .navbar {
    padding: 0;
  }
  .navbar-collapse {
    flex-basis: 100%;
    flex-grow: 1;
    align-items: center;
    background: #203793;
    /* position: fixed;
        left: 0;
        top: 0;
        height: 100%;
        width: 100%;*/
    padding: 30px 0px;
  }
  .btn-outline-white {
    border: none;
  }
  nav .btn-xl {
    background: none;
    box-shadow: none;
  }

  header.masthead {
    height: 50vh;
    min-height: 40rem;
  }

  .masthead h1 {
    font-size: 40px;
    line-height: 50px;
    margin-bottom: 20px;
  }
  footer {
    padding: 100px 50px 0px;
  }
  .login100-more {
    display: none;
  }

  .login100-form {
    padding: 63px 89px;
    width: 100%;
  }
  .basic .wrap .left {
    margin-top: 1rem;
  }
  .basic {
    display: block;
  }
  .basic .sign-up {
    width: 100%;
    min-height: auto;
  }
  .basic .wrap {
    padding: 90px 31px;
  }
}
@media (max-width: 768px) {
  /*----------------------------------------- Dashboard ------------------------------------------- */
  .dashboard_header {
    padding: 18px 20px 18px 20px;
  }

  .dasboard_main_area {
    padding: 40px 20px 70px 20px;
  }

  .dashboard_header .user-name {
    display: none;
  }

  .dashboard_header .header_menu_item {
    padding: 0px 10px;
  }

  .counter-card-row .card-head-main {
    flex-direction: column;
  }

  .dashboard_header h1 {
    font-size: 20px;
  }

  /*----------------------------------------- Dashboard ------------------------------------------- */
  .notification-open {
    display: inline;
    position: absolute;
    right: -150%;
    top: 62px;
    background: #ffffff;
    width: calc(100% + 300px);
    /* min-width: 440px; */
  }
  .experts-table-filter .question-filter-box {
    right: 0;
  }
  .my-question-status .select_container .dropholder {
    width: 100%;
    margin-left: auto;
  }
  .my-question-section .myquestion-details .date-row p {
    padding: 0px 0px 5px 10px;
    white-space: nowrap;
  }
  .question-details-col.date.d-flex {
    margin: 0px;
    flex-wrap: wrap;
    justify-content: flex-end;
  }
  .respond-btn-main .btn:first-child {
    margin-right: 0px;
    margin-bottom: 10px;
  }
  .respond-btn-main .btn {
    width: 100%;
  }

  /*----------------------------------- points ---------------------------- */
  .points-counter .points-available {
    padding-bottom: 15px;
    margin: auto;
  }
  .points-counter .points-btns {
    width: 100%;
  }
  .points-counter .btn {
    width: 100%;
    margin-left: auto;
    margin-right: auto !important;
  }
  .points-counter .btn-outline {
    margin-bottom: 10px;
  }
  .points .coupon {
    display: block;
  }
  .points .coupon-code::after {
    display: none;
  }

  .points-area .dashboard_container_points {
    max-width: 100%;
  }
  /*----------------------------------- points ---------------------------- */
}

@media (max-width: 568px) {
  .masthead h1 {
    font-size: 30px;
  }

  .login100-form {
    padding: 23px 39px;
    width: 100%;
  }
  .submit-wrapper .btn-outline {
    margin-right: 0px;
    width: 100%;
    margin-bottom: 10px;
  }
  .submit-wrapper .btn-xl {
    width: 100%;
  }
}

/*-------------------------------------User Management Edit ------------------------------------*/
.list-experts-area {
  padding: 30px 36px 60px 60px;
}

.u-section {
  margin-bottom: 20px;
  display: flex;
}

.u-section .u-title {
  font-family: 'Poppins';
  font-weight: 600;
  text-transform: uppercase;
  color: #0d9edf;
}

.u-section .u-title .u-name {
  font-family: 'Poppins';
  text-transform: uppercase;
  font-weight: 500;
}

.u-section .left-arrow {
  margin-right: 5px;
  margin-top: -3px;
}

.user-avatar .profile-pic-wrap {
  background-color: #eeeeee;
  width: 132px;
  height: 128px;
  border-radius: 50%;
  overflow: hidden;
}

.user-edit .form-control,
select,
input {
  border-bottom: 1px solid rgba(0, 8, 66, 0.5);
  border-top: 1px solid rgba(0, 8, 66, 0.5);
  border-left: 1px solid rgba(0, 8, 66, 0.5);
  border-right: 1px solid rgba(0, 8, 66, 0.5);
  width: 100%;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #000842;
  background-color: #fff;
  border-radius: 0.25rem;
}

.user-edit .form-group {
  margin-right: 25px;
}

.user-edit .form-label {
  font-size: 13px;
  font-weight: 500;
  color: rgba(153, 153, 153, 1);
  font-family: 'Poppins';
}

.user-edit .btn-outline,
.user-avatar .btn-outline {
  border: 1px solid #0d9edf;
  box-sizing: border-box;
  border-radius: 32px;
  height: 53px;
  width: 129px;
  line-height: 38px;
  color: #0d9edf;
  font-weight: 500;
  font-family: 'Poppins';
  background-color: transparent;
}

.user-edit .btn-outline:hover,
.user-avatar .btn-outline:hover {
  background-color: #0d9edf;
  color: #ffffff;
}

/* input[type='number']::-webkit-inner-spin-button {
  opacity: 1;
} */

.nav-tabs .nav-link:hover {
  border-top-color: #f4f7fc;
  border-left-color: #f4f7fc;
  border-right-color: #f4f7fc;
}

.user-management-table {
  background-color: #ffffff;
}

.user-management-table thead {
  background: #e1f2fa;
  border: 1px solid rgba(227, 231, 235, 1);
}

.user-management-table thead th {
  text-transform: uppercase;
  font-size: 12px;
  font-weight: 700;
  font-family: 'Poppins';
  padding: 14px;
}

.user-management-table tbody td {
  font-weight: 500;
  font-size: 14px;
  font-weight: 700;
  font-family: 'Poppins';
  padding: 18px;
}

.user-management-table input[type='checkbox'] {
  width: 1em;
  height: 1em;
}

.view-link {
  color: #0d9edf;
  font-weight: 600;
  text-decoration: underline;
  cursor: pointer;
}



@media (max-width: 768px) {
  .list-experts-area {
    padding: 30px 30px 20px 20px;
  }
  .user-edit .form-group {
    margin-right: 0px;
  }
}

  /*----------------------------------------- Point View Dashboard ------------------------------------------- */

  .pointViewWrapper{
    max-width: 950px;
  }
  .pointViewWrapper .heading{
    margin-bottom: 50px;
  }
.pointViewWrapper label{
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 27px;
  color: #000000;
  text-transform: capitalize;
}
.pointViewWrapper p{
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 27px;
  color: #000000;
  text-transform: capitalize;
}
.pointViewWrapper input{
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 27px;
  color: #000000;
  text-transform: capitalize;
  border: none;
  background: transparent;
}
.pointViewWrapper input:focus{
  border: 1px solid grey;
  padding: 0 10px;
}
.pointViewWrapper div.row{
  margin-bottom: 25px;
}
.pv-question{
  border: 1px solid rgba(0, 8, 66, 0.5);
  border-radius: 6px;
  padding: 10px;
}
.pv-question p{
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 180%;
  color: #000000;
}
.pointViewWrapper .resAnswer{
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 180%;
  color: #000000;
  margin-bottom: 15px;
}
.pointViewWrapper .editableArea{
  width: 100%;
  resize: none;
  border: none;
  background: transparent;
}

#legal-textarea{
  width: 100%;
  height: 100%;
  background: transparent;
  resize: none;
}

.pointViewWrapper .closeControl{
  margin-top: -15px;
}
.pointViewWrapper .closeControl button{
  cursor: pointer;
  padding: 0 0;
  border: none;
  background: transparent;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  -webkit-text-decoration-line: underline;
          text-decoration-line: underline;
  color: #0D9EDF;
}
@media (max-width: 768px) {
  .pointViewWrapper .heading{
    margin-bottom: 25px;      
  } 
  .pointViewWrapper label{
    margin-bottom: 10px;
  }
  .pointViewWrapper div.row {
    margin-bottom: 15px;
  }
}

.FaqViewPage .heading h3{
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 27px;
  color: #000000;
  margin-bottom: 50px;
  text-transform: capitalize;
}
.FaqViewPage label{
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 20px;
  color: #999;
  margin-bottom: 7px;
}
.FaqViewPage .row{
    margin-bottom: 25px;
}
.FaqViewPage .buttonsWrapper button{
  width: 167px;
  height: 53px;
  margin-top: 25px;
}

@media (max-width: 768px) {
  .FaqViewPage .heading h3{
    margin-bottom: 25px;      
  } 
  
  .FaqViewPage div.row {
    margin-bottom: 15px;
  }
}

.legalSetting .tab-container{
  max-width: 100%;
}
.legalSetting .content-wrapper{
  background: #FFFFFF;
  border: 1px solid #E3E7EB;
  border-radius: 6px;
  padding: 20px 20px 48px 24px;
  height: 350px;
}
.legalSetting .content-wrapper p{
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 180%;
  color: #000000; 
}
.legalSetting .legalForm{
  max-width: 495px;
  margin-top: 5px;
}
.legalSetting .legalForm .buttonsWrapper{
  margin-top:50px;
}
.legalSetting .legalForm label{
  margin-bottom: 7px;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 20px;
  color: #999999; 
  margin-top: 25px;
}
.legalSetting .legalForm input{
  border: 1px solid rgba(0, 8, 66, 0.5);
  border-radius: 6px;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #000842; 
}
.legalSetting .legalForm .buttonsWrapper .btn{
    width: 167px;
    padding: 0px 20px;

}

/* .bell-icon{
  display: none;
} */

.notification-box{
  width : 100%;
}

.App .notification-toast{
  z-index: 2;
}

.dashboard_header{
  z-index: 1;
}

.red-notification {
  content: '';
    width: 14px;
    height: 14px;
    border-radius: 50%;
    background-color: #e23737;
    position: absolute;
    right: 6px;
    top: -1px;
    font-size: 9px;
    padding-left: 2px;
    color: #FFFFFF;
    font-weight: bold;
}
/*----------------------------------------- Admin Dashboard  ------------------------------------*/

.admin-dashboard .counter-card-row .counter-cards .inner-column {
  min-height: 170px;
}

.dashboard_main_section .admin-dashboard {
  padding-top: 90px;
}

/*----------------------------------------- Admin Dashboard  ------------------------------------*/

/*----------------------------------------- Admin User Management Dashboard  ------------------------------------*/
.nav-tabs .nav-item button {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 27px;
  color: #808080;
  position: relative;
}
.txt-3 {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 22px;
  text-transform: uppercase;
  color: #0d9edf;
}
.txt-2 {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  color: #000000;
}
.nav-tabs .nav-item:first-child button {
  padding-left: 0px;
}
.nav-tabs .nav-item:first-child button::after {
  display: none;
}
.form-text {
  margin-top: 0.25rem;
  font-size: 12px;
  text-align: right;
  display: block;
  color: #0d9edf;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  cursor: pointer;
}
.admin .form-control,
select,
input {
  border: 1px solid rgba(0, 8, 66, 0.5);
  border-radius: 6px;
}
.radio .css-ahj2mt-MuiTypography-root {
  margin: 0;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.5;
  font-family: "Poppins";
  font-weight: 500;
  font-size: 18px;
  line-height: 27px;
  color: #000000;
}
.card-type-section {
  margin: 0px -20px;
}
.nav-tabs .nav-item button::after {
  content: "";
  width: 1px;
  height: 20px;
  background-color: #808080;
  position: absolute;
  left: 0px;
  top: 50%;
  transform: translateY(-50%);
}

.search-filter input {
  background: #f6f8fb;
  border: 1px solid #e3e7ed;
  box-sizing: border-box;
  border-radius: 100px;
  padding: 8px 15px 8px 40px;
  width: 100%;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
}

.search-filter input::placeholder {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  color: #617798;
}

.search-filter .search-icon {
  width: 12px;
  height: 12px;
  background-image: url(/static/media/search-icon.958f0567.svg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  position: absolute;
  left: 20px;
  top: 50%;
  transform: translateY(-50%);
}

.admin-user-table-section .list_experts_table thead th:not(:first-child),
.admin-user-table-section .list_experts_table tbody td:not(:first-child) {
  width: unset;
}

.admin-user-table-section .list_experts_table thead th:first-child,
.admin-user-table-section .list_experts_table tbody td:first-child {
  padding: 14px 15px 14px 20px;
}

.admin-user-table-section .list_experts_table tbody td:last-child,
.admin-user-table-section .list_experts_table thead th:last-child {
  padding: 14px 20px 14px 15px;
  text-align: center;
}

.admin-user-table-section .list_experts_table thead th:last-child,
.admin-user-table-section .list_experts_table tbody td:last-child {
  width: 4%;
}

.admin-user-table-section .option-cell {
  cursor: pointer;
}

.option-cell .opt-menu {
  list-style: none;
  width: 100px;
  box-shadow: 0px 5px 24px rgba(0, 0, 0, 0.1);
  border-radius: 0px 0px 4px 4px;
  background-color: #fff;
  padding: 0px 10px;
  position: absolute;
  right: 5px;
  top: 40px;
  z-index: 2;
}

.option-cell .opt-menu .opt-menu-item span {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  color: #072387;
  display: block;
  padding-left: 5px;
  transition: all 0.5s;
}

.option-cell .opt-menu .opt-menu-item:hover span {
  color: #0d9edf;
}

.option-cell .opt-menu .opt-menu-item {
  padding: 7px 0px;
  text-align: left;
  border-bottom: 0.5px solid rgba(0, 0, 0, 0.1);
}

.option-cell .opt-menu .opt-menu-item:last-child {
  border: none;
}

.admin-user-filter-row {
  width: 90%;
}

.pt-25 {
  padding-top: 25px;
}

/*----------------------------------------- Admin User Management Dashboard  ------------------------------------*/

/*----------------------------------------- Admin User Details  ------------------------------------*/

.user-details-form .inner-row .inner-column {
  padding-bottom: 25px;
}

.field-wrapper .btn-change {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 18px;
  text-align: right;
  color: #0d9edf;
  display: block;
  margin-top: 4px;
  cursor: pointer;
}

.user-details-form .field-wrapper input {
  color: #000842;
}

.user-details-form .field-wrapper .select_container p {
  color: #000842;
  padding: 10px;
}

.user-details-form .field-wrapper .select_container .dropdownMenu {
  top: 50px;
}

.user-details-form .field-wrapper .select_container .dropdownMenu li {
  font-size: 16px;
}

.user-details-form .inner-row .change-field {
  padding-bottom: 14px;
}

.user-details-form .select_container .dropholder {
  border-radius: 6px;
}

.user-details-form .img-ctrls-main .btn {
  width: 75%;
  margin: 0px auto 15px auto;
  height: 45px;
  font-size: 15px;
  line-height: 45px;
  padding: 0px 10px;
}

.user-details-form .user-profile-img {
  display: block;
  max-width: 100px;
  min-height: 100px;
  border-radius: 50%;
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAFIAAABSCAYAAADHLIObAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAPdSURBVHgB7Z2BVdswEIb/dIHCBBUTUDYIExQmwBsAExAmYAToBLQThE4AnSDpBNAJjjtLckISCBYnLNn63jtCHu9F9p+TdD7pxAiJQEQ7/PKd7Qebcb/vOBOe2Obu9YHtD9vdaDR6QqEWcMw2ZXukMK7lMzBUlgTUYsZWYSjwzRplAVe5lTbQZ/gGTyi8C7dB2qjQR/jGLujzuUCf6EjEfonZsYievMXkG6goHU4QkREiQXbmvMcioO4aCdwPOICfIwJfEI8rpCOiINdyjUhEEZJs6HGE9JCHgDEiEKVr88XOYJ+XU0Sezw+hjLpHum/cIF2ieGWMrh11dlRCPRxS7dpkU2GPSB+Zwfc0U3DaHjlGHvjcpxpDFVJQjSq0hdxHPhgooi1kSgH4NlS/dG0hDfJB9UvXnrUJGcGzttr9x3zWHhTaQua0NKp6rUMWcg5FtIV8QD4k7ZH/kA9/oYi2kL+QD3dQJEbSQnKROQTmu8kmLdyF5TBO3mhvvooRR14ifX5CmVhLDVOkmwmaszfuQZlYTzYpe2UOPWYBxd1xFsotIlE2CCgRLWnhLvgc6XAeS8RPgT1zQt0zQR+gbsWcoE90JOYEfYTsNr+y9VkDspvxbykeU+r7ZvxlyHrnjPSYUqm3oRsK4zEVAaMF5G0hm4IbO5M1Z3lv8LKEzmeXJIEsuc+HUkJXKBQioj5G8lgn2+XEDNtXLMY4Gdd8qbDkBKNk0smGPr79b3g5xv6HHWOjtR+MTBJsZ9S+VHhGtkT4yE00H2m/cp/Vpn0/41ekEHcGe6Rr/JStgk6q7A6Lgvbac1ezNa5Nv0l0372OocMN2+WnZYicB1xRf5F7a+0YrTySbOArRT8G/WbOdtxmHH13YpdsYaQsahn0H8N2T9rFoJRGhWtX6IhJwxbRs1XM0RYRK0QshMyMisfMVzcWvCokpbcK2DVvrkK+NdnIxFJEXPBmmfJGIdkbJdA2KKwiudOzTX9Y69quSw8lzAlhYx3jJo+UGcqg8BrSxde88oVHOm+cobCNNa9c9chTFN7DmleuemTKRyikxhN75K5/03gkpX+EQmrs0NLq5XLXzuEIhdQ48r80Xbt06yCa7l17JNl1FoNCW6R7y7pQ07VVz3cYGPUZQkXIj1Nr54XM6SyK1DDywwtZsjzh1E7ohTQohFI7YR3+SC4dhWDkbIxypoUSXsiyxzCcWjsvZFobivKi1s4L+RuFUOqVRT/ZyMwjK4YGhTY0Jcu1R7pM7zHKWNkG0ao5YraZtd2GoQMon4fTU+Zsh8tr3Bs3CLgdFpKf9P+cp7CoqJD5ZO1MjGfyO0y98djltgAAAABJRU5ErkJggg==);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  margin: 0px auto 30px auto;
}

.container-test {
  /* background-color: #089216; */
  /* display: flex;
  flex-direction: row;
  justify-content: center; */

  position: relative;
  /* max-width: 100px;
  min-height: 100px; */
  border-radius: 50%;
  /* margin-left: 80px; */
}

.image-test {
  opacity: 1;
  display: block;
  max-width: 100px;
  min-height: 100px;
  border-radius: 50%;
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAFIAAABSCAYAAADHLIObAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAPdSURBVHgB7Z2BVdswEIb/dIHCBBUTUDYIExQmwBsAExAmYAToBLQThE4AnSDpBNAJjjtLckISCBYnLNn63jtCHu9F9p+TdD7pxAiJQEQ7/PKd7Qebcb/vOBOe2Obu9YHtD9vdaDR6QqEWcMw2ZXukMK7lMzBUlgTUYsZWYSjwzRplAVe5lTbQZ/gGTyi8C7dB2qjQR/jGLujzuUCf6EjEfonZsYievMXkG6goHU4QkREiQXbmvMcioO4aCdwPOICfIwJfEI8rpCOiINdyjUhEEZJs6HGE9JCHgDEiEKVr88XOYJ+XU0Sezw+hjLpHum/cIF2ieGWMrh11dlRCPRxS7dpkU2GPSB+Zwfc0U3DaHjlGHvjcpxpDFVJQjSq0hdxHPhgooi1kSgH4NlS/dG0hDfJB9UvXnrUJGcGzttr9x3zWHhTaQua0NKp6rUMWcg5FtIV8QD4k7ZH/kA9/oYi2kL+QD3dQJEbSQnKROQTmu8kmLdyF5TBO3mhvvooRR14ifX5CmVhLDVOkmwmaszfuQZlYTzYpe2UOPWYBxd1xFsotIlE2CCgRLWnhLvgc6XAeS8RPgT1zQt0zQR+gbsWcoE90JOYEfYTsNr+y9VkDspvxbykeU+r7ZvxlyHrnjPSYUqm3oRsK4zEVAaMF5G0hm4IbO5M1Z3lv8LKEzmeXJIEsuc+HUkJXKBQioj5G8lgn2+XEDNtXLMY4Gdd8qbDkBKNk0smGPr79b3g5xv6HHWOjtR+MTBJsZ9S+VHhGtkT4yE00H2m/cp/Vpn0/41ekEHcGe6Rr/JStgk6q7A6Lgvbac1ezNa5Nv0l0372OocMN2+WnZYicB1xRf5F7a+0YrTySbOArRT8G/WbOdtxmHH13YpdsYaQsahn0H8N2T9rFoJRGhWtX6IhJwxbRs1XM0RYRK0QshMyMisfMVzcWvCokpbcK2DVvrkK+NdnIxFJEXPBmmfJGIdkbJdA2KKwiudOzTX9Y69quSw8lzAlhYx3jJo+UGcqg8BrSxde88oVHOm+cobCNNa9c9chTFN7DmleuemTKRyikxhN75K5/03gkpX+EQmrs0NLq5XLXzuEIhdQ48r80Xbt06yCa7l17JNl1FoNCW6R7y7pQ07VVz3cYGPUZQkXIj1Nr54XM6SyK1DDywwtZsjzh1E7ohTQohFI7YR3+SC4dhWDkbIxypoUSXsiyxzCcWjsvZFobivKi1s4L+RuFUOqVRT/ZyMwjK4YGhTY0Jcu1R7pM7zHKWNkG0ao5YraZtd2GoQMon4fTU+Zsh8tr3Bs3CLgdFpKf9P+cp7CoqJD5ZO1MjGfyO0y98djltgAAAABJRU5ErkJggg==);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  margin: 0px auto 30px auto;
  transition: .5s ease;
  -webkit-backface-visibility: hidden;
          backface-visibility: hidden;
}

.overlay {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  max-width: 100px;
  /* min-height: 100px; */
  height: 100%;
  border-radius: 50%;
  opacity: 0;
  transition: .3s ease;
  background-color: rgba(255, 252, 252, 0.253);
  margin: 0px auto 30px auto;
  display: block;
}

.container-test:hover .overlay {
  opacity: 1;
}

.icon-test {
  color: white;
  font-size: 50px;
  position: absolute;
  top: 50%;
  left: 50%;
  max-width: 100px;
  min-height: 100px;
  border-radius: 50%;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  text-align: center;
  margin-top: 10px;
}

.fa-user:hover {
  color: #eee;
}



.error-label{
  color: red;
}

.col-md-4 .error-label{
  /* background-color: pink; */
  width: 220px;
}

.user-details-form .user-form-submit .btn {
  width: 167px;
}

/*----------------------------------------- Admin User Details  ------------------------------------*/
/*----------------------------------------- Poinst History------------------------------------*/
.points-history-section .custom-radio-btn:last-child input[type="radio"]:after {
  transform: translate(-54%, -54%);
}

.points-history-section .points-table-section {
  margin-top: 30px;
}

.points-history-section .points-tb-wrapper table th,
.points-history-section .points-tb-wrapper table td {
  padding: 14px 20px;
  white-space: nowrap;
}

.points-history-section .points-tb-wrapper table th:first-child,
.points-history-section .points-tb-wrapper table td:first-child {
  padding: 14px 0px 14px 10px;
  width: 5%;
  text-align: center;
}

.points-history-section .points-tb-wrapper table .input-box input {
  margin-right: 0px;
}

.points-history-section .points-table .action-cell {
  width: 62%;
}

.points-history-section .points-tb-wrapper {
  overflow-x: auto;
}

/*----------------------------------------- Points History------------------------------------*/

/*----------------------------------------- Question Managment ------------------------------------*/
.admin-user-filter-row .column {
  flex-wrap: wrap;
}
.experts-table-filter .filter-box {
  background: #ffffff;
  box-shadow: 0px 5px 24px rgb(0 0 0 / 10%);
  border-radius: 8px;
  padding: 35px 28px;
  width: 360px;
  position: absolute;
  right: 95px;
  top: 14px;
  z-index: 2;
  text-align: left;
}
.filter-btn-row button {
  height: 42px;
  width: 90px;
  margin-top: 25px;
  filter: none;
}



.form-select {
  background-image: url(/static/media/down.34748194.svg);
  background-size: 16px 6px;
  cursor: pointer;
}
.admin .form-select {
  border: 1px solid rgba(0, 8, 66, 0.5);
  box-sizing: border-box;
  border-radius: 6px;
  height: 42px;
}
.delete {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 27px;
}
/*----------------------------------------- Question Managment ------------------------------------*/

/*----------------------------------------- Points Managment ------------------------------------*/
.dropdown-menu {
  box-shadow: 0px 5px 24px rgb(0 0 0 / 10%);
  border-radius: 0px 0px 4px 4px;
}
.dropdown-item {
  font-family: "Poppins";
  font-weight: 500;
  font-size: 15px;
  line-height: 22px;
  color: #0d9edf;
}
.btn-check:focus + .btn,
.btn:focus {
  box-shadow: none;
}
.dropdown-toggle::after {
  display: none;
}
.points-manage-section .points-available label {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 27px;
  color: #000000;
}

#points-history-admin-question{
  position: relative;
  max-width: 300px;
  overflow-x: auto;
  /* background-color: #089216; */
  /* padding-left: 20px; */
}

#points-history-admin-date{
  position: relative;
  width: 300px;
  overflow-x: auto;
}

#points-history-admin-point{
  position: relative;
  width: 200px;
  overflow-x: auto;
  /* background-color: #072387; */
}

.points-manage-section .points-available {
  width: 100%;
  padding-bottom: 50px;
  display: flex;
  justify-content: space-between;
}
.points-manage-section .points-available .pointHistory{
  text-align: right;
}
.points-manage-section .points-available .pointHistory a{
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 27px;
  color: #0D9EDF;
  -webkit-text-decoration-line:underline;
          text-decoration-line:underline;
}
.points-manage-section .points-available .pointHistory p{
  /* margin-top: 24px; */
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 27px;
  color: #000000;
}
.points-manage-section {
  padding-top: 30px;
}

.points-manage-section .currency {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  color: #000000;
}

.md-switch {
  text-align: end;
}

.md-switch-input {
  display: none;
}

.md-switch-label {
  position: relative;
  cursor: pointer;
  margin: 0;
}

.md-switch-label-rail {
  transition: all 0.4s cubic-bezier(0.55, -0.69, 0.33, 1.38);
  position: absolute;
  width: 28px;
  height: 16px;
  background: #0d9edf;
  border-radius: 16px;
  bottom: -2px;
  right: 4px;
}

.md-switch-label-rail-slider {
  transition: all 0.4s cubic-bezier(0.55, -0.69, 0.33, 1.38);
  position: absolute;
  top: 1px;
  left: 1px;
  width: 13.5px;
  height: 13.5px;
  border-radius: 50%;
  background: #fff;
  box-shadow: 0 5px 10px -5px rgba(0, 0, 0, 0.1),
    0 3px 5px -4px rgba(0, 0, 0, 0.175);
}

.md-switch #switch:checked + .md-switch-label .md-switch-label-rail {
  transition: all 0.4s cubic-bezier(0.55, -0.69, 0.33, 1.38);
  background: #0d9edf;
}

.md-switch
  #switch:checked
  + .md-switch-label
  .md-switch-label-rail
  .md-switch-label-rail-slider {
  transition: all 0.4s cubic-bezier(0.55, -0.69, 0.33, 1.38);
  transform: translateX(92%);
}

.notifiaction-label {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  color: #000000;
}

/*-----------------------------------------  Points Managment ------------------------------------*/

/*----------------------------------------- Faq Managment ------------------------------------*/
.modal-header {
  padding: 10px;
}
.modal-header .btn-close {
  padding: 7px;
}
.btn-close {
  width: 0px;
  height: 0px;
  opacity: 1;
}
.faq-manage-area .add-new-question .plus-btn {
  width: 200px;
  padding: 13px 20px 13px 15px;
}

.faq-manage-area .add-new-question .plus-btn::before {
  left: 46px;
}

.faq-manage-area .faq-manage-section {
  margin-top: 50px;
  border: 1px solid #e3e7eb;
  box-sizing: border-box;
  border-radius: 6px 6px 0px 0px;
}

.faq-manage-section .table-head {
  background-color: #e1f2fa;
  padding: 14px 25px;
}

.faq-manage-section .table-head h4 {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 16px;
  text-transform: uppercase;
  color: #000000;
  margin-bottom: 0px;
}

.faq-manage-section .tb-body {
  background-color: #fff;
}

.faq-manage-section .tb-body .tb-row {
  padding: 18px 25px;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #e3e7eb;
}

.faq-manage-section .tb-body .tb-row:last-child {
  border-bottom: none;
}

.faq-manage-section .tb-body .faq-main {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  color: #000000;
  padding-right: 15px;
}

.faq-manage-section .opt-icon {
  display: inline-block;
  padding: 0px 5px;
  cursor: pointer;
}

.faq-manage-section .option-cell .opt-menu {
  right: 0px;
  top: 25px;
}

.faq-manage-section .option-cell:hover .opt-menu {
  display: block;
}

/*----------------------------------------- Faq Managment ------------------------------------*/
.wallet-bal-field .bal-label {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 27px;
  color: #000000;
  padding-right: 10px;
}
.wallet-balance .account-transfer-link span {
  cursor: pointer;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  -webkit-text-decoration-line: underline;
          text-decoration-line: underline;
  color: #0d9edf;
  margin-top: 9px;
  display: block;
}
.pay-manage-section .complteted-badge {
  width: 110px;
  display: inline-block;
  padding: 4px 16px;
  border-radius: 4px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  color: #089216;
  background-color: #d0ffd5;
  border: 1px solid rgba(8, 146, 22, 0.3);
  border-radius: 4px;
  text-align: center;
}
.pay-manage-section .rejected-badge {
  width: 110px;
  display: inline-block;
  padding: 4px 16px;
  border-radius: 4px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  color: #e41818;
  background: #fad1d1;
  border: 1px solid rgba(255, 87, 87, 0.3);
  border-radius: 4px;
  text-align: center;
}
.badge-management .tabs_pannel {
  margin-top: 50px;
}
.badge-management .form-control {
  width: 73px;
}
.badge-management label {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  /* identical to box height */

  color: #000000;
}
.badge-manage-form .badge-col:first-child .label-wrapper {
  width: calc(100% - 74px);
  padding-left: 15px;
  position: relative;
  white-space: nowrap;
}
.badge-manage-form .plus-symbol {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.badge-manage-form .plus-symbol span {
  cursor: pointer;
  font-weight: 500;
  margin-top: -5px;
}
.badge-manage-form .badge-col:not(:first-child) .label-wrapper {
  width: calc(100% - 60px);
  padding-left: 15px;
  position: relative;
  white-space: nowrap;
}
.tab-container {
  max-width: 60%;
}
.w-110 .form-control {
  width: 110px;
}
.effect button {
  width: 129px;
  height: 53px;
  margin-top: 60px;
}
/*----------------------------------------- User Reports Dashboard ------------------------------------*/

.report-dashboard-ctrls
  .card-type-section
  .custom-radio-btn:last-child
  input[type="radio"]:after {
  transform: translate(-47%, -44%);
}

.report-dashboard-ctrls
  .card-type-section
  .custom-radio-btn:first-child
  input[type="radio"]:after {
  transform: translate(-54%, -46%);
}

.report-dashboard-ctrls {
  flex-wrap: wrap;
}

.report-dashboard-ctrls .export-btn {
  padding: 13px 20px;
  width: 178px;
}

.dashboard_main_section .reports-dashboard {
  padding-top: 30px;
}

.reports-main {
  margin-top: 40px;
}

.dashboard_main_section .reports-container {
  max-width: 980px;
}

.reports-main .counter-card-row {
  margin: 0px -5px 50px -5px;
}

.reports-main .counter-card-row .counter-cards {
  padding: 0px 5px;
}

.reports-main .counter-card-row .inner-column {
  padding: 25px 10px;
}

.reports-main .counter-card-row .card-inner-cols {
  padding: 0px 5px;
}

.reports-main .counter-card-row .counter-card-img {
  flex: 0 0 20%;
  max-width: 20%;
}

.reports-main .counter-card-row .counter-card-content {
  flex: 0 0 80%;
  max-width: 80%;
}

.reports-main .counter-card-row select {
  width: 102px;
}

.report-graph-area {
  background-color: #fff;
  padding: 30px;
  border-radius: 10px;
  margin-top: 10px;
}

.user-reports-section .counter-card-row {
  margin: 0px -25px;
}

.user-reports-section .reports-main .counter-card-row .counter-cards {
  padding: 0px 25px;
}
.form-check-label {
  margin-left: 10px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 27px;
  color: #000000;
}
.reports-main .counter-card-row .counter-cards {
  padding: 0px 5px;
}
/*----------------------------------------- User Reports Dashboard ------------------------------------*/
@media (max-width: 1024px) {
  /*----------------------------------------- Admin User Management Dashboard  ------------------------------------*/
  .table-exp-main .admin-user-filter-row {
    padding: 13px 10px;
  }
  .tab-container {
    max-width: 100%;
  }

  .admin-user-filter-row {
    width: 100%;
  }

  .list_experts_table tbody tr:last-child .opt-menu,
  .list_experts_table tbody tr:nth-last-child(-n + 2) .option-cell .opt-menu,
  .list_experts_table tbody tr:nth-last-child(-n + 3) .option-cell .opt-menu {
    top: unset;
    bottom: 0px;
    right: 30px;
  }

  /*----------------------------------------- Admin User Management Dashboard  ------------------------------------*/

  /*----------------------------------------- Faq Managment ------------------------------------*/

  .faq-manage-section .tb-body .tb-row {
    padding: 18px 20px;
  }

  /*----------------------------------------- Faq Managment ------------------------------------*/
}

@media (max-width: 767px) {
  /*----------------------------------------- Admin User Management Dashboard  ------------------------------------*/

  .list-experts-area .experts-table-filter .search-filter {
    margin-bottom: 10px;
    width: 100%;
  }

  .list-experts-area .admin-user-filter-row {
    margin-left: auto !important;
    margin-right: auto !important;
  }

  .admin-user-filter-row .filter-col {
    margin-bottom: 10px;
  }

  /*----------------------------------------- Admin User Management Dashboard  ------------------------------------*/

  /*----------------------------------------- Admin User Details ------------------------------------*/
  .user-details-form .outer-row {
    flex-wrap: wrap-reverse;
  }

  .user-details-form .profile-col {
    margin-bottom: 20px;
  }

  .user-details-form .user-form-submit .btn {
    width: 100%;
  }

  .pt-25 {
    padding-top: 20px;
  }

  .points-history-section .points-table .action-cell {
    width: 90%;
  }

  /*----------------------------------------- Admin User Details ------------------------------------*/

  /*----------------------------------------- Question Managment ------------------------------------*/
  .manage-question {
    flex-wrap: wrap;
  }

  .manage-question .column {
    width: 100%;
    text-align: left;
  }

  /*----------------------------------------- Question Managment ------------------------------------*/

  /*----------------------------------------- Points Managment ------------------------------------*/
  .points-manage-section .points-available {
    padding-bottom: 30px;
  }

  .points-manage-section .columns {
    margin-bottom: 15px;
  }

  .points-manage-section .columns:nth-last-child(-n + 2) {
    margin-bottom: 0px;
  }

  .points-manage-section .columns:last-child {
    margin-bottom: 0px;
  }

  /*----------------------------------------- Points Managment ------------------------------------*/
}
@media (max-width:567px) {
  .experts-table-filter .filter-box {
    width: 100%;
    right: 0;
  }
}
