/*----------------------------------------- Admin Dashboard  ------------------------------------*/

.admin-dashboard .counter-card-row .counter-cards .inner-column {
  min-height: 170px;
}

.dashboard_main_section .admin-dashboard {
  padding-top: 90px;
}

/*----------------------------------------- Admin Dashboard  ------------------------------------*/

/*----------------------------------------- Admin User Management Dashboard  ------------------------------------*/
.nav-tabs .nav-item button {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 27px;
  color: #808080;
  position: relative;
}
.txt-3 {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 22px;
  text-transform: uppercase;
  color: #0d9edf;
}
.txt-2 {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  color: #000000;
}
.nav-tabs .nav-item:first-child button {
  padding-left: 0px;
}
.nav-tabs .nav-item:first-child button::after {
  display: none;
}
.form-text {
  margin-top: 0.25rem;
  font-size: 12px;
  text-align: right;
  display: block;
  color: #0d9edf;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  cursor: pointer;
}
.admin .form-control,
select,
input {
  border: 1px solid rgba(0, 8, 66, 0.5);
  border-radius: 6px;
}
.radio .css-ahj2mt-MuiTypography-root {
  margin: 0;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.5;
  font-family: "Poppins";
  font-weight: 500;
  font-size: 18px;
  line-height: 27px;
  color: #000000;
}
.card-type-section {
  margin: 0px -20px;
}
.nav-tabs .nav-item button::after {
  content: "";
  width: 1px;
  height: 20px;
  background-color: #808080;
  position: absolute;
  left: 0px;
  top: 50%;
  transform: translateY(-50%);
}

.search-filter input {
  background: #f6f8fb;
  border: 1px solid #e3e7ed;
  box-sizing: border-box;
  border-radius: 100px;
  padding: 8px 15px 8px 40px;
  width: 100%;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
}

.search-filter input::placeholder {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  color: #617798;
}

.search-filter .search-icon {
  width: 12px;
  height: 12px;
  background-image: url("../img/search-icon.svg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  position: absolute;
  left: 20px;
  top: 50%;
  transform: translateY(-50%);
}

.admin-user-table-section .list_experts_table thead th:not(:first-child),
.admin-user-table-section .list_experts_table tbody td:not(:first-child) {
  width: unset;
}

.admin-user-table-section .list_experts_table thead th:first-child,
.admin-user-table-section .list_experts_table tbody td:first-child {
  padding: 14px 15px 14px 20px;
}

.admin-user-table-section .list_experts_table tbody td:last-child,
.admin-user-table-section .list_experts_table thead th:last-child {
  padding: 14px 20px 14px 15px;
  text-align: center;
}

.admin-user-table-section .list_experts_table thead th:last-child,
.admin-user-table-section .list_experts_table tbody td:last-child {
  width: 4%;
}

.admin-user-table-section .option-cell {
  cursor: pointer;
}

.option-cell .opt-menu {
  list-style: none;
  width: 100px;
  box-shadow: 0px 5px 24px rgba(0, 0, 0, 0.1);
  border-radius: 0px 0px 4px 4px;
  background-color: #fff;
  padding: 0px 10px;
  position: absolute;
  right: 5px;
  top: 40px;
  z-index: 2;
}

.option-cell .opt-menu .opt-menu-item span {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  color: #072387;
  display: block;
  padding-left: 5px;
  transition: all 0.5s;
}

.option-cell .opt-menu .opt-menu-item:hover span {
  color: #0d9edf;
}

.option-cell .opt-menu .opt-menu-item {
  padding: 7px 0px;
  text-align: left;
  border-bottom: 0.5px solid rgba(0, 0, 0, 0.1);
}

.option-cell .opt-menu .opt-menu-item:last-child {
  border: none;
}

.admin-user-filter-row {
  width: 90%;
}

.pt-25 {
  padding-top: 25px;
}

/*----------------------------------------- Admin User Management Dashboard  ------------------------------------*/

/*----------------------------------------- Admin User Details  ------------------------------------*/

.user-details-form .inner-row .inner-column {
  padding-bottom: 25px;
}

.field-wrapper .btn-change {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 18px;
  text-align: right;
  color: #0d9edf;
  display: block;
  margin-top: 4px;
  cursor: pointer;
}

.user-details-form .field-wrapper input {
  color: #000842;
}

.user-details-form .field-wrapper .select_container p {
  color: #000842;
  padding: 10px;
}

.user-details-form .field-wrapper .select_container .dropdownMenu {
  top: 50px;
}

.user-details-form .field-wrapper .select_container .dropdownMenu li {
  font-size: 16px;
}

.user-details-form .inner-row .change-field {
  padding-bottom: 14px;
}

.user-details-form .select_container .dropholder {
  border-radius: 6px;
}

.user-details-form .img-ctrls-main .btn {
  width: 75%;
  margin: 0px auto 15px auto;
  height: 45px;
  font-size: 15px;
  line-height: 45px;
  padding: 0px 10px;
}

.user-details-form .user-profile-img {
  display: block;
  max-width: 100px;
  min-height: 100px;
  border-radius: 50%;
  background-image: url("../img/user.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  margin: 0px auto 30px auto;
}

.container-test {
  /* background-color: #089216; */
  /* display: flex;
  flex-direction: row;
  justify-content: center; */

  position: relative;
  /* max-width: 100px;
  min-height: 100px; */
  border-radius: 50%;
  /* margin-left: 80px; */
}

.image-test {
  opacity: 1;
  display: block;
  max-width: 100px;
  min-height: 100px;
  border-radius: 50%;
  background-image: url("../img/user.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  margin: 0px auto 30px auto;
  transition: .5s ease;
  backface-visibility: hidden;
}

.overlay {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  max-width: 100px;
  /* min-height: 100px; */
  height: 100%;
  border-radius: 50%;
  opacity: 0;
  transition: .3s ease;
  background-color: rgba(255, 252, 252, 0.253);
  margin: 0px auto 30px auto;
  display: block;
}

.container-test:hover .overlay {
  opacity: 1;
}

.icon-test {
  color: white;
  font-size: 50px;
  position: absolute;
  top: 50%;
  left: 50%;
  max-width: 100px;
  min-height: 100px;
  border-radius: 50%;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  text-align: center;
  margin-top: 10px;
}

.fa-user:hover {
  color: #eee;
}



.error-label{
  color: red;
}

.col-md-4 .error-label{
  /* background-color: pink; */
  width: 220px;
}

.user-details-form .user-form-submit .btn {
  width: 167px;
}

/*----------------------------------------- Admin User Details  ------------------------------------*/
/*----------------------------------------- Poinst History------------------------------------*/
.points-history-section .custom-radio-btn:last-child input[type="radio"]:after {
  transform: translate(-54%, -54%);
}

.points-history-section .points-table-section {
  margin-top: 30px;
}

.points-history-section .points-tb-wrapper table th,
.points-history-section .points-tb-wrapper table td {
  padding: 14px 20px;
  white-space: nowrap;
}

.points-history-section .points-tb-wrapper table th:first-child,
.points-history-section .points-tb-wrapper table td:first-child {
  padding: 14px 0px 14px 10px;
  width: 5%;
  text-align: center;
}

.points-history-section .points-tb-wrapper table .input-box input {
  margin-right: 0px;
}

.points-history-section .points-table .action-cell {
  width: 62%;
}

.points-history-section .points-tb-wrapper {
  overflow-x: auto;
}

/*----------------------------------------- Points History------------------------------------*/

/*----------------------------------------- Question Managment ------------------------------------*/
.admin-user-filter-row .column {
  flex-wrap: wrap;
}
.experts-table-filter .filter-box {
  background: #ffffff;
  box-shadow: 0px 5px 24px rgb(0 0 0 / 10%);
  border-radius: 8px;
  padding: 35px 28px;
  width: 360px;
  position: absolute;
  right: 95px;
  top: 14px;
  z-index: 2;
  text-align: left;
}
.filter-btn-row button {
  height: 42px;
  width: 90px;
  margin-top: 25px;
  filter: none;
}



.form-select {
  background-image: url(../img/down.svg);
  background-size: 16px 6px;
  cursor: pointer;
}
.admin .form-select {
  border: 1px solid rgba(0, 8, 66, 0.5);
  box-sizing: border-box;
  border-radius: 6px;
  height: 42px;
}
.delete {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 27px;
}
/*----------------------------------------- Question Managment ------------------------------------*/

/*----------------------------------------- Points Managment ------------------------------------*/
.dropdown-menu {
  box-shadow: 0px 5px 24px rgb(0 0 0 / 10%);
  border-radius: 0px 0px 4px 4px;
}
.dropdown-item {
  font-family: "Poppins";
  font-weight: 500;
  font-size: 15px;
  line-height: 22px;
  color: #0d9edf;
}
.btn-check:focus + .btn,
.btn:focus {
  box-shadow: none;
}
.dropdown-toggle::after {
  display: none;
}
.points-manage-section .points-available label {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 27px;
  color: #000000;
}

#points-history-admin-question{
  position: relative;
  max-width: 300px;
  overflow-x: auto;
  /* background-color: #089216; */
  /* padding-left: 20px; */
}

#points-history-admin-date{
  position: relative;
  width: 300px;
  overflow-x: auto;
}

#points-history-admin-point{
  position: relative;
  width: 200px;
  overflow-x: auto;
  /* background-color: #072387; */
}

.points-manage-section .points-available {
  width: 100%;
  padding-bottom: 50px;
  display: flex;
  justify-content: space-between;
}
.points-manage-section .points-available .pointHistory{
  text-align: right;
}
.points-manage-section .points-available .pointHistory a{
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 27px;
  color: #0D9EDF;
  text-decoration-line:underline;
}
.points-manage-section .points-available .pointHistory p{
  /* margin-top: 24px; */
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 27px;
  color: #000000;
}
.points-manage-section {
  padding-top: 30px;
}

.points-manage-section .currency {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  color: #000000;
}

.md-switch {
  text-align: end;
}

.md-switch-input {
  display: none;
}

.md-switch-label {
  position: relative;
  cursor: pointer;
  margin: 0;
}

.md-switch-label-rail {
  transition: all 0.4s cubic-bezier(0.55, -0.69, 0.33, 1.38);
  position: absolute;
  width: 28px;
  height: 16px;
  background: #0d9edf;
  border-radius: 16px;
  bottom: -2px;
  right: 4px;
}

.md-switch-label-rail-slider {
  transition: all 0.4s cubic-bezier(0.55, -0.69, 0.33, 1.38);
  position: absolute;
  top: 1px;
  left: 1px;
  width: 13.5px;
  height: 13.5px;
  border-radius: 50%;
  background: #fff;
  box-shadow: 0 5px 10px -5px rgba(0, 0, 0, 0.1),
    0 3px 5px -4px rgba(0, 0, 0, 0.175);
}

.md-switch #switch:checked + .md-switch-label .md-switch-label-rail {
  transition: all 0.4s cubic-bezier(0.55, -0.69, 0.33, 1.38);
  background: #0d9edf;
}

.md-switch
  #switch:checked
  + .md-switch-label
  .md-switch-label-rail
  .md-switch-label-rail-slider {
  transition: all 0.4s cubic-bezier(0.55, -0.69, 0.33, 1.38);
  transform: translateX(92%);
}

.notifiaction-label {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  color: #000000;
}

/*-----------------------------------------  Points Managment ------------------------------------*/

/*----------------------------------------- Faq Managment ------------------------------------*/
.modal-header {
  padding: 10px;
}
.modal-header .btn-close {
  padding: 7px;
}
.btn-close {
  width: 0px;
  height: 0px;
  opacity: 1;
}
.faq-manage-area .add-new-question .plus-btn {
  width: 200px;
  padding: 13px 20px 13px 15px;
}

.faq-manage-area .add-new-question .plus-btn::before {
  left: 46px;
}

.faq-manage-area .faq-manage-section {
  margin-top: 50px;
  border: 1px solid #e3e7eb;
  box-sizing: border-box;
  border-radius: 6px 6px 0px 0px;
}

.faq-manage-section .table-head {
  background-color: #e1f2fa;
  padding: 14px 25px;
}

.faq-manage-section .table-head h4 {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 16px;
  text-transform: uppercase;
  color: #000000;
  margin-bottom: 0px;
}

.faq-manage-section .tb-body {
  background-color: #fff;
}

.faq-manage-section .tb-body .tb-row {
  padding: 18px 25px;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #e3e7eb;
}

.faq-manage-section .tb-body .tb-row:last-child {
  border-bottom: none;
}

.faq-manage-section .tb-body .faq-main {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  color: #000000;
  padding-right: 15px;
}

.faq-manage-section .opt-icon {
  display: inline-block;
  padding: 0px 5px;
  cursor: pointer;
}

.faq-manage-section .option-cell .opt-menu {
  right: 0px;
  top: 25px;
}

.faq-manage-section .option-cell:hover .opt-menu {
  display: block;
}

/*----------------------------------------- Faq Managment ------------------------------------*/
.wallet-bal-field .bal-label {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 27px;
  color: #000000;
  padding-right: 10px;
}
.wallet-balance .account-transfer-link span {
  cursor: pointer;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  text-decoration-line: underline;
  color: #0d9edf;
  margin-top: 9px;
  display: block;
}
.pay-manage-section .complteted-badge {
  width: 110px;
  display: inline-block;
  padding: 4px 16px;
  border-radius: 4px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  color: #089216;
  background-color: #d0ffd5;
  border: 1px solid rgba(8, 146, 22, 0.3);
  border-radius: 4px;
  text-align: center;
}
.pay-manage-section .rejected-badge {
  width: 110px;
  display: inline-block;
  padding: 4px 16px;
  border-radius: 4px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  color: #e41818;
  background: #fad1d1;
  border: 1px solid rgba(255, 87, 87, 0.3);
  border-radius: 4px;
  text-align: center;
}
.badge-management .tabs_pannel {
  margin-top: 50px;
}
.badge-management .form-control {
  width: 73px;
}
.badge-management label {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  /* identical to box height */

  color: #000000;
}
.badge-manage-form .badge-col:first-child .label-wrapper {
  width: calc(100% - 74px);
  padding-left: 15px;
  position: relative;
  white-space: nowrap;
}
.badge-manage-form .plus-symbol {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.badge-manage-form .plus-symbol span {
  cursor: pointer;
  font-weight: 500;
  margin-top: -5px;
}
.badge-manage-form .badge-col:not(:first-child) .label-wrapper {
  width: calc(100% - 60px);
  padding-left: 15px;
  position: relative;
  white-space: nowrap;
}
.tab-container {
  max-width: 60%;
}
.w-110 .form-control {
  width: 110px;
}
.effect button {
  width: 129px;
  height: 53px;
  margin-top: 60px;
}
/*----------------------------------------- User Reports Dashboard ------------------------------------*/

.report-dashboard-ctrls
  .card-type-section
  .custom-radio-btn:last-child
  input[type="radio"]:after {
  transform: translate(-47%, -44%);
}

.report-dashboard-ctrls
  .card-type-section
  .custom-radio-btn:first-child
  input[type="radio"]:after {
  transform: translate(-54%, -46%);
}

.report-dashboard-ctrls {
  flex-wrap: wrap;
}

.report-dashboard-ctrls .export-btn {
  padding: 13px 20px;
  width: 178px;
}

.dashboard_main_section .reports-dashboard {
  padding-top: 30px;
}

.reports-main {
  margin-top: 40px;
}

.dashboard_main_section .reports-container {
  max-width: 980px;
}

.reports-main .counter-card-row {
  margin: 0px -5px 50px -5px;
}

.reports-main .counter-card-row .counter-cards {
  padding: 0px 5px;
}

.reports-main .counter-card-row .inner-column {
  padding: 25px 10px;
}

.reports-main .counter-card-row .card-inner-cols {
  padding: 0px 5px;
}

.reports-main .counter-card-row .counter-card-img {
  flex: 0 0 20%;
  max-width: 20%;
}

.reports-main .counter-card-row .counter-card-content {
  flex: 0 0 80%;
  max-width: 80%;
}

.reports-main .counter-card-row select {
  width: 102px;
}

.report-graph-area {
  background-color: #fff;
  padding: 30px;
  border-radius: 10px;
  margin-top: 10px;
}

.user-reports-section .counter-card-row {
  margin: 0px -25px;
}

.user-reports-section .reports-main .counter-card-row .counter-cards {
  padding: 0px 25px;
}
.form-check-label {
  margin-left: 10px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 27px;
  color: #000000;
}
.reports-main .counter-card-row .counter-cards {
  padding: 0px 5px;
}
/*----------------------------------------- User Reports Dashboard ------------------------------------*/
@media (max-width: 1024px) {
  /*----------------------------------------- Admin User Management Dashboard  ------------------------------------*/
  .table-exp-main .admin-user-filter-row {
    padding: 13px 10px;
  }
  .tab-container {
    max-width: 100%;
  }

  .admin-user-filter-row {
    width: 100%;
  }

  .list_experts_table tbody tr:last-child .opt-menu,
  .list_experts_table tbody tr:nth-last-child(-n + 2) .option-cell .opt-menu,
  .list_experts_table tbody tr:nth-last-child(-n + 3) .option-cell .opt-menu {
    top: unset;
    bottom: 0px;
    right: 30px;
  }

  /*----------------------------------------- Admin User Management Dashboard  ------------------------------------*/

  /*----------------------------------------- Faq Managment ------------------------------------*/

  .faq-manage-section .tb-body .tb-row {
    padding: 18px 20px;
  }

  /*----------------------------------------- Faq Managment ------------------------------------*/
}

@media (max-width: 767px) {
  /*----------------------------------------- Admin User Management Dashboard  ------------------------------------*/

  .list-experts-area .experts-table-filter .search-filter {
    margin-bottom: 10px;
    width: 100%;
  }

  .list-experts-area .admin-user-filter-row {
    margin-left: auto !important;
    margin-right: auto !important;
  }

  .admin-user-filter-row .filter-col {
    margin-bottom: 10px;
  }

  /*----------------------------------------- Admin User Management Dashboard  ------------------------------------*/

  /*----------------------------------------- Admin User Details ------------------------------------*/
  .user-details-form .outer-row {
    flex-wrap: wrap-reverse;
  }

  .user-details-form .profile-col {
    margin-bottom: 20px;
  }

  .user-details-form .user-form-submit .btn {
    width: 100%;
  }

  .pt-25 {
    padding-top: 20px;
  }

  .points-history-section .points-table .action-cell {
    width: 90%;
  }

  /*----------------------------------------- Admin User Details ------------------------------------*/

  /*----------------------------------------- Question Managment ------------------------------------*/
  .manage-question {
    flex-wrap: wrap;
  }

  .manage-question .column {
    width: 100%;
    text-align: left;
  }

  /*----------------------------------------- Question Managment ------------------------------------*/

  /*----------------------------------------- Points Managment ------------------------------------*/
  .points-manage-section .points-available {
    padding-bottom: 30px;
  }

  .points-manage-section .columns {
    margin-bottom: 15px;
  }

  .points-manage-section .columns:nth-last-child(-n + 2) {
    margin-bottom: 0px;
  }

  .points-manage-section .columns:last-child {
    margin-bottom: 0px;
  }

  /*----------------------------------------- Points Managment ------------------------------------*/
}
@media (max-width:567px) {
  .experts-table-filter .filter-box {
    width: 100%;
    right: 0;
  }
}